import React, {useEffect, useState} from "react";
import Layout from "../../layout/interface/Layout";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  CheckIcon, Mail, Phone, PhoneIcon, SquareArrowOutUpRight, Zap,
} from "lucide-react";
import {
  getBundleService, getPackagesService,
} from "./TradingInvesting.service";
import {
  DIGIO_ENV,
} from "../../../config/constants.config";
// import * as Yup from "yup";
import {MakePaymentProcess} from "../../../components/MakePaymentProcess";
import PlaneCompare from "./PlaneCompare";
import TradingInvestingFAQ from "./TradingInvestingFAQ";
import {useDocumentTitle} from "../../../components/Common";
import TradingInvestingPlans from "../../../components/TradingInvestingPlans";

export default function TradingInvesting() {

  // const hasCartOld = JSON.parse(localStorage.getItem("cart_total")) || {
  //   total: 0, gst: 0, grandTotal: 0,
  // };
  // const [hasCart, setHasCart] = useState(hasCartOld);

  const navigate = useNavigate();


  useDocumentTitle('Trading & Investing');

  return (<Layout><TradingInvestingPlans/></Layout>);
}
