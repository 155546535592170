import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
export const Ltp = ({price, diff = 0, chng = 0}) => {
  const chngWith2Decimal = Number(parseFloat(chng).toFixed(2));
  const diffWith2Decimal = Number(parseFloat(diff).toFixed(2));
  return (
    <>
      <small className="fw-semibold text-body-emphasis">
        ₹{Number(price).toFixed(2)}
        <br/>
      </small>
      <div className="fs-small fw-bold align-items-center">
        <span
          className={`${
            diffWith2Decimal === 0
              ? ""
              : diffWith2Decimal > 0
                ? "text-success"
                : "text-danger"
          }`}
        >
          {
            diffWith2Decimal !== 0 && (
              diffWith2Decimal === 0
                ? diffWith2Decimal
                : diffWith2Decimal > 0
                  ? "+" + diffWith2Decimal
                  : diffWith2Decimal
            )
          }
        </span>

        <span
          className={`mx-1 ${
            chngWith2Decimal === 0
              ? ""
              : chngWith2Decimal > 0
                ? "text-success"
                : "text-danger"
          }`}
        >
          (
          {chngWith2Decimal === 0
            ? chngWith2Decimal
            : chngWith2Decimal > 0
              ? "+" + chngWith2Decimal
              : chngWith2Decimal}
          %)
          {/* {chng == 0 ? (
            ""
          ) : chng > 0 ? (
            <i className="text-success fa-solid fa-caret-up" />
          ) : (
            <i className="text-danger fa-solid fa-caret-down" />
          )} */}
        </span>
      </div>
    </>
  );
};

export const StockRange = ({price, high, low}) => {
  let priceDiff = ((price - low) / (high - low)) * 100;
  isNaN(priceDiff) ? (priceDiff = 50) : priceDiff;
  return (
    <>
      <div className="d-flex justify-content-between align-items-top w-100">
        <small className="text-body-emphasis">
          <small className="text-danger fs-small">L</small> {low}
        </small>
        <small className="text-body-emphasis">
          {high} <small className="text-success fs-small">H</small>
        </small>
      </div>
      <div
        className="bg-primary bg-opacity-25 w-100 pt-1"
        style={{position: "relative"}}
      >
        <small
          style={{
            position: "absolute",
            marginTop: "-4px",
            left: `${priceDiff}%`,
          }}
        >
          <i className="text-primary fa-solid fa-sort-up"/>
        </small>
      </div>
      {/* <div className="d-flex justify-content-between align-items-bottom mt-1 w-100">
        <small className="text-danger fw-semibold fs-small">L</small>
        <small className="text-success fw-semibold fs-small">H</small>
      </div> */}
    </>
  );
};

export const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const TradingView = ({coCode}) => {
  return (
    <div
      className="modal fade m-0 p-0"
      id="tradingViewChartLabel"
      tabIndex="-1"
      aria-labelledby="tradingViewChartLabelLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-fullscreen p-0 m-0">
        <div className="modal-content p-0 m-0">
          <div className="modal-body p-0 m-0 overflow-hidden position-relative">
            <div
              className="text-danger uni-cursor-pointer position-absolute start-50"
              data-bs-dismiss="modal"
              aria-label="Close"
            ><i className="fa-solid fa-xmark-circle"  aria-hidden="true"></i></div>
            <iframe
              className="w-100 h-100"
              src={`https://www.equitypandit.com/trading-view?co_code=${coCode}&theme=dark`}>
            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export const NonSubscribedBanner = () => {
  const navigate = useNavigate();
  const handleGoPro = () => {
    navigate("/payment-details");
  };
  return (
    <button className="btn btn-primary position-absolute z-1" style={{
      top: "25%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "20px"
    }}
    onClick={() => handleGoPro()}
    >
      <div>
        To view signals and get ahead from 90% trader
      </div>
      <i className="fa fa-crown text-warning"></i>{" "}<b>Go Pro</b>
    </button>
  );
};

export const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

export const handleScrollToTop = () => {
  setTimeout(() => {
    if ("scrollBehavior" in document.documentElement.style) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
    }
  }, 250);
};
