import React, {useState} from "react";
import {useDocumentTitle} from "../../../components/Common";
import {submitContact} from "../../contact-us/ContactUs.service";
import {CheckIcon, MoveRight} from "lucide-react";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Layout from "../../layout/interface/Layout";
const InstitutionalInvestors = () => {
  const [successMessage, setSuccessMessage] = useState();
  const [failedMessage, setFailedMessage] = useState();
  useDocumentTitle('Institutional Investors');
  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Name is required')
      .max(150, 'Name should be 150 characters only.'),
    email: Yup.string()
      .required('Mobile is required')
      .max(150, 'Email should be 150 characters only.'),
    mobile: Yup.string()
      .required('Mobile is required')
      .min(10, 'Mobile must be 10 digits.')
      .max(10, 'Mobile must be 10 digits.'),
  });
  return (
    <Layout>
      <section className="p-0">
        <div className="container-fluid mb-5" style={{backgroundImage: `url('/assets/img/bg.png')`}}>
          <div className="container">
            <div className="row py-5 align-items-center">
              <div className="col-md-8">
                <div className="p-3 ps-3 mb-4 mt-4 text-black border-0 rounded-3 fs-18">
                  <h1 className="fw-bold text-uppercase">Institutional investor</h1>
                  <div
                    className="my-3">{`Ep Institutional investor is designed to cater to traders of all experience levels. Whether you're a seasoned pro or just starting out, our index options recommendations will help you navigate the complex world of options trading with ease. Our team of analysts is always monitoring the market, keeping an eye out for the latest trends and shifts in market sentiment with on our cutting-edge algorithms and market analysis.`}
                  </div>
                  <button className="btn btn-md btn-success ">Get Started <MoveRight size={20}/></button>
                </div>
              </div>
              <div className="col-md-4 mt-3 mb-3">
                {/*<div className="col-lg-8 col-12">*/}
                <div className="text-end col-12 rounded-2 box_shadow p-1 bg-white">
                  <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                      name: '',
                      email: '',
                      mobile: '',
                      page: 'institutional-investor',
                      source: 'equityPandit',
                      service: 'Institutional_Investor',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                      resetForm();
                      setSubmitting(true);
                      submitContact(values)
                        .then(res => {
                          setSuccessMessage(res.data.message);
                        })
                        .catch(e => {
                          setFailedMessage(e.response.data.message)
                        });
                    }}
                    enableReinitialize
                  >
                    {({isSubmitting, touched, errors, values}) => (
                      <Form className="mx-md-4 my-md-3">
                        <div className="row">
                          <div className="col-12 ">
                            <div className="my-3 text-center fw-bolder fs-18">Fill Up The
                              Form
                            </div>
                          </div>

                          {successMessage &&
                            <div className="text-center mb-2 text-success">
                              <small><b>{successMessage}</b></small>
                            </div>}

                          {failedMessage &&
                            <div className="text-center mb-2 text-danger">
                              <small><b>{failedMessage}</b></small>
                            </div>}

                          <div className="col-12 mt-3">
                            <Field
                              name="name"
                              placeholder="Name*"
                              className={`form-control rounded-3 py-2 px-3 show-tick ${touched.name && errors.name ? 'is-invalid' : 'border-dark-subtle'}`}
                              value={values.name}
                            />
                            <ErrorMessage name="name" component="div"
                                          className="invalid-feedback"/>
                          </div>
                          <div className="col-12 mt-3">
                            <Field
                              name="email"
                              placeholder="Email*"
                              className={`form-control rounded-3 py-2 px-3 show-tick ${touched.email && errors.email ? 'is-invalid' : 'border-dark-subtle'}`}
                              value={values.email}
                            />
                            <ErrorMessage name="email" component="div"
                                          className="invalid-feedback"/>
                          </div>
                          <div className="col-12 mt-3">
                            <Field
                              name="mobile"
                              placeholder="Mobile*"
                              className={`form-control rounded-3 py-2 px-3 show-tick ${touched.mobile && errors.mobile ? 'is-invalid' : 'border-dark-subtle'}`}
                              value={values.mobile}
                            />
                            <ErrorMessage name="mobile" component="div"
                                          className="invalid-feedback"/>
                          </div>
                          <div className="col-12">
                            <div className="text-center my-lg-0 my-2">
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-default text-white py-2 px-4 mt-2 bg-success">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-8">
            <div className="">
              <h1 className="fw-bold text-uppercase">
                Institutional investor
              </h1>
              <small className="">Suitable Risk Profile </small>
              <small className="bg-success bg-opacity-50 px-2 py-1 rounded-3">Aggressive</small>
              <p className="mt-3 fs-18">
                {`Ep Index Options is designed to cater to traders of all experience levels. Whether you're a seasoned pro or just starting out, our index options recommendations will help you navigate the complex world of options trading with ease. Our team of analysts is always monitoring the market, keeping an eye out for the latest trends and shifts in market sentiment with on our cutting-edge algorithms and market analysis.`}
              </p>
              <hr className="my-4"/>
              <h3 className="fw-bold">What you get?</h3>
              <ul className="list-unstyled">
                <li className="my-2 fs-18"><CheckIcon/> Herein we will provide you Intraday
                  recommendations in Index Options.
                </li>
                <li className="my-2 fs-18"><CheckIcon/> Follow Ups & All Important News & Information.
                </li>
                <li className="my-2 fs-18"><CheckIcon/> Nifty Review, Resistance & Support.</li>
                <li className="my-2 fs-18"><CheckIcon/> Pre-Market trend of Sensex and Nifty.</li>
              </ul>
              <hr className="my-4"/>
              <h3 className="fw-bold">Trader Profile: Aggressive</h3>
              <ul className="list-unstyled">
                <li className="my-2 fs-18"><CheckIcon/> Full time / Part time traders can subscribe this
                  package.
                </li>
                <li className="my-2 fs-18"><CheckIcon/> Should execute all the trades on time.</li>
                <li className="my-2 fs-18"><CheckIcon/> Should have access to Email / Phone.</li>
                <li className="my-2 fs-18"><CheckIcon/> Minimum Investment Suggested: Rs.30,000.</li>
              </ul>
              <hr className="my-4"/>
              <h3 className="fw-bold">Terms of Agreement:</h3>
              <ul className="list-unstyled">
                <li className="my-2 fs-18"><CheckIcon/> Full time / Part time traders can subscribe this
                  package.
                </li>
                <li className="my-2 fs-18"><CheckIcon/> Should execute all the trades on time.</li>
                <li className="my-2 fs-18"><CheckIcon/> Should have access to Email / Phone.</li>
                <li className="my-2 fs-18"><CheckIcon/> Minimum Investment Suggested: Rs.30,000.</li>
              </ul>
            </div>
          </div>
          {/*<div className="col-md-4">*/}
          {/*  <div className="sticky-top text-center border bg-white pb-3 z-0 " style={{top: "15%"}}>*/}
          {/*    <div className="py-3"*/}
          {/*         style={{*/}
          {/*           backgroundImage: `url('/assets/img/card_header.svg')`,*/}
          {/*           backgroundRepeat: "no-repeat"*/}
          {/*         }}>*/}
          {/*      <h4 className="text-uppercase fw-bold">Institutional investor</h4>*/}
          {/*      <p className="pb-0 fw-normal">Go Premium Get Awesome</p>*/}

          {/*    </div>*/}
          {/*    <div className="d-flex justify-content-center mt-3">*/}
          {/*                    <span*/}
          {/*                      className="badge bg-secondary-subtle rounded-2 fw-bold text-black">DERIVATIVES</span>*/}
          {/*      <span*/}
          {/*        className="badge ms-3 bg-primary-subtle rounded-2 fw-bold text-black text-uppercase">intraday</span>*/}
          {/*    </div>*/}
          {/*    <div className="mt-3 mb-2 fw-400 fs-16 text-black">*/}
          {/*      Medium Plan |*/}
          {/*      6 Months*/}
          {/*    </div>*/}
          {/*    <div className="fw-bold mb-2 fs-16 text-muted">*/}
          {/*      /!*<s>₹45,000/-</s>*!/*/}
          {/*      /!*<small className="fs-12"><s>+GST</s></small>*!/*/}
          {/*    </div>*/}
          {/*    <div className="fw-bold mb-3 fs-22 text-black">*/}
          {/*      ₹50,000/-*/}
          {/*      <small className="fs-12">+GST</small>*/}
          {/*    </div>*/}
          {/*    <div className="d-flex justify-content-center mt-3">*/}
          {/*      <button className="btn btn-sm btn-success rounded-2">Pay Now</button>*/}
          {/*      <button className="btn btn-sm btn-outline-success rounded-2 ms-3">Contact Us</button>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </Layout>
  );
};

export default InstitutionalInvestors;
