import { Navigate, Route } from "react-router";
import Welcome from "./pages/welcome/interface/Welcome";
import TheCompany from "./pages/the-company/theCompany";
import ContactUs from "./pages/contact-us/ContactUs";
import TradingInvesting from "./pages/service/trading-investing/TradingInvesting";
import InstitutionalInvestors from "./pages/service/institutional-investors/InstitutionalInvestors";
import HniUhni from "./pages/service/hni-uhni/HniUhni";
import Careers from "./pages/careers/Careers";
import Service from "./pages/service/Services";
import IntelligentInvestor from "./pages/service/trading-investing/intelligent-investor/IntelligentInvestor";
import PassionateTrader from "./pages/service/trading-investing/passionate-trader/PassionateTrader";
import StockCashPremium from "./pages/service/trading-investing/stock-cash-premium/StockCashPremium";
import GetStarted from "./pages/auth/interface/GetStarted";
import Dashboard from "./pages/dashboard/Dashboard";
import InvestorCharter from "./pages/investor-charter/InvestorCharter";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsConditions from "./pages/terms-conditions/TermsConditions";
import ComplianceAudit from "./pages/compliance-audit/ComplianceAudit";
import PowerPlus from "./pages/service/trading-investing/power-plus/PowerPlus";


let parsedPersist = JSON.parse(localStorage.getItem("persist:root"));
let isLoggedIn = false;
if(parsedPersist?.isLoggedIn) {
  isLoggedIn = parsedPersist?.isLoggedIn !== "false";
}

const allRoutes = [
  {
    path: "/",
    component: <Welcome />
  },
  {
    path: "/get-started",
    component: <GetStarted />
  },
  {
    path: "/dashboard",
    component: <Dashboard />
  },
  {
    path: "/the-company",
    component: <TheCompany />
  },
  {
    path: "/contact-us",
    component: <ContactUs />
  },
  {
    path: "/services/trading-investing",
    component: <TradingInvesting />
  },
  {
    path: "/services/trading-investing/intelligent-investor",
    component: <IntelligentInvestor />
  },
  {
    path: "/services/trading-investing/power-plus",
    component: <PowerPlus />
  },
  {
    path: "/services/trading-investing/passionate-trader",
    component: <PassionateTrader />
  },
  {
    path: "/services/trading-investing/stock-cash-premium",
    component: <StockCashPremium />
  },
  {
    path: "services/institutional-investors",
    component: <InstitutionalInvestors />
  },
  {
    path: "services/hni-uhni",
    component: <HniUhni />
  },
  {
    path: "careers",
    component: <Careers />
  },
  {
    path: "services",
    component: <Service />
  },
  {
    path: "investment-charter",
    component: <InvestorCharter />
  },
  {
    path: "privacy-policy",
    component: <PrivacyPolicy />
  },
  {
    path: "terms-conditions",
    component: <TermsConditions />
  },
  {
    path: "compliance-audit",
    component: <ComplianceAudit />
  },

];

export default allRoutes.map((row, i) => {
  return (<Route path={row.path} key={i}
    element={isLoggedIn ? <Navigate to="/" /> : row.component}>
  </Route>);
});

