import React, {useEffect, useState} from "react";
import {handleScrollToTop, useDocumentTitle} from "../../../components/Common";
import {submitContact} from "../../contact-us/ContactUs.service";
import {CheckIcon, Contact, MoveRight} from "lucide-react";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Layout from "../../layout/interface/Layout";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {Chart} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);
import { Bar } from "react-chartjs-2";
import PlaneCompare from "../trading-investing/PlaneCompare";
import TradingInvestingFAQ from "../trading-investing/TradingInvestingFAQ";
import {useNavigate} from "react-router-dom";

const HniUhni = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS on component mount
    // define the sample data for three companies for years 2016-2022
    const companyData1 = {
      "Nifty": [12],
      "Mutual_Funds": [15],
      "EP_Wealth": [55]
    };
    // // define the initial year to display
    // const initialYear1 = "EP Wealth Returns";
    // // get the canvas element and create a new chart object
    // const ctx1 = document.getElementById('returns').getContext('2d');
    // const myChart1 = new Chart(ctx1, {
    //   type: 'bar',
    //   data:{
    //     labels: [2016, 2017, 2018, 2019, 2020, 2021, 2022],
    //     datasets:[{
    //       label: initialYear1,
    //       backgroundColor: "#FFFFFF",
    //       borderWidth: 1,
    //     }]
    //   },
    //   plugins: [ChartDataLabels],
    //   options: {
    //     responsive: true,
    //     barPercentage: 0.6,
    //     plugins: {
    //       legend: {
    //         display: false,
    //         position: 'bottom'
    //       },
    //       title: {
    //         display: true,
    //         text: 'EP Wealth Returns in Last 7 Years',
    //         font: {
    //           size: 16,
    //           weight: 'bold',
    //           family: 'Noto Sans',
    //         },
    //         color: '#FFFFFF',
    //         position: 'bottom'
    //       },
    //       datalabels: {
    //         display: true,
    //         color: '#FFFFFF',
    //         anchor: 'end',
    //         align: 'top',
    //         font: {
    //           size: 12 //Change this to the desired font size
    //         },
    //         formatter: function (value) {
    //           return value + '%';
    //         },
    //       },
    //       tooltip: {
    //         callbacks: {
    //           label: function (context) {
    //             let label = context.dataset.label + ': ' + context.parsed.y + "%";
    //             return label;
    //           }
    //         }
    //       }
    //     },
    //     scales: {
    //       x: {
    //         stacked: true,
    //         ticks: {
    //           color: '#FFFFFF',
    //         },
    //       },
    //       y: {
    //         ticks: {
    //           callback: function (value) {
    //             return value + "%"
    //           },
    //           color: '#FFFFFF',
    //           fontWeight: 500,
    //           beginAtZero: true
    //         },
    //         stacked: true,
    //       }
    //     }
    //   },
    //   // data: {
    //   //   labels: [2016, 2017, 2018, 2019, 2020, 2021, 2022],
    //   //   datasets: [{
    //   //     label: initialYear1,
    //   //     backgroundColor: "#FFFFFF",
    //   //     borderWidth: 1,
    //   //     data: [39.06, 34.04, 59.22, 37.43, 106.99, 70.74, 50.31]
    //   //   }]
    //   // },
    // });
  }, []);
  const [successMessage, setSuccessMessage] = useState();
  const [failedMessage, setFailedMessage] = useState();
  const router = useNavigate();
  useDocumentTitle('HNI');
  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Name is required')
      .max(150, 'Name should be 150 characters only.'),
    email: Yup.string()
      .required('Mobile is required')
      .max(150, 'Email should be 150 characters only.'),
    mobile: Yup.string()
      .required('Mobile is required')
      .min(10, 'Mobile must be 10 digits.')
      .max(10, 'Mobile must be 10 digits.'),
  });

  function handleRedirect(link) {
    router(link);
    handleScrollToTop();
  }

  return (
    <Layout>
      <section className="p-0">
        <div className="container-fluid mb-5" style={{backgroundImage: `url('/assets/img/bg.png')`}}>
          <div className="container">
            <div className="row py-5 align-items-center">
              <div className="col-md-8">
                <div className="p-3 ps-3 mb-4 mt-4 text-black border-0 rounded-3 fs-18">
                  <h1 className="fw-bold text-uppercase">HNI & UHNI</h1>
                  <div
                    className="my-3">{`Ep HNI & UHNI is designed to cater to traders of all experience levels. Whether you're a seasoned pro or just starting out, our index options recommendations will help you navigate the complex world of options trading with ease. Our team of analysts is always monitoring the market, keeping an eye out for the latest trends and shifts in market sentiment with on our cutting-edge algorithms and market analysis.`}
                  </div>
                  <button className="btn btn-md btn-success ">Get Started</button>
                </div>
              </div>
              <div className="col-md-4 mt-3 mb-3">
                {/*<div className="col-lg-8 col-12">*/}
                <div className="text-end col-12 rounded-2 box_shadow p-1 bg-white">
                  <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                      name: '',
                      email: '',
                      mobile: '',
                      page: 'hni-uhni',
                      source: 'equityPandit',
                      service: "Hni-Uhni"
                    }}
                    validationSchema={validationSchema} //
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                      resetForm();
                      setSubmitting(true);
                      submitContact(values)
                        .then(res => {
                          setSuccessMessage(res.data.message);
                        })
                        .catch(e => {
                          setFailedMessage(e.response.data.message)
                        });
                    }}
                    enableReinitialize
                  >
                    {({isSubmitting, touched, errors, values}) => (
                      <Form className="mx-md-4 my-md-3">
                        <div className="row">
                          <div className="col-12 ">
                            <div className="my-3 text-center fw-bolder fs-18">Fill Up The Form</div>
                          </div>

                          {successMessage &&
                            <div className="text-center mb-2 text-success">
                              <small><b>{successMessage}</b></small>
                            </div>}

                          {failedMessage &&
                            <div className="text-center mb-2 text-danger">
                              <small><b>{failedMessage}</b></small>
                            </div>}

                          <div className="col-12 mt-3">
                            <Field
                              name="name"
                              placeholder="Name*"
                              className={`form-control rounded-3 py-2 px-3 show-tick ${touched.name && errors.name ? 'is-invalid' : 'border-dark-subtle'}`}
                              value={values.name}
                            />
                            <ErrorMessage name="name" component="div" className="invalid-feedback"/>
                          </div>
                          <div className="col-12 mt-3">
                            <Field
                              name="email"
                              placeholder="Email*"
                              className={`form-control rounded-3 py-2 px-3 show-tick ${touched.email && errors.email ? 'is-invalid' : 'border-dark-subtle'}`}
                              value={values.email}
                            />
                            <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                          </div>
                          <div className="col-12 mt-3">
                            <Field
                              name="mobile"
                              placeholder="Mobile*"
                              className={`form-control rounded-3 py-2 px-3 show-tick ${touched.mobile && errors.mobile ? 'is-invalid' : 'border-dark-subtle'}`}
                              value={values.mobile}
                            />
                            <ErrorMessage name="mobile" component="div" className="invalid-feedback"/>
                          </div>
                          <div className="col-12">
                            <div className="text-center my-lg-0 my-2">
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-default text-white py-2 px-4 mt-2 bg-success">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="rank" className=" rank-sec">
        <div className="container">
          <div className="row align-items-center bg-white justify-content-center rounded-2">
            <div className="col-12">
              <div className="py-5 mb-3">
                <div className="row bg-white gap-5 gap-lg-0 justify-content-center rounded-2">
                  <div className="col-12 col-lg-8 col-sticky">
                    <div className="px-2 px-lg-0 sticky-element text-center text-lg-start">
                      <div
                        className="fw-semi-bold fs-1 fs-res-28 mb-3 pb-1 pb-lg-0 text-center text-lg-start">
                        <div data-aos-duration="500" data-aos="fade-up" className="text-primary-emphasis fw-bolder">
                          Equitypandit Ranked #1
                        </div>
                        <div data-aos-duration="500" data-aos="fade-up" className="text-black fw-semibold">
                          Investment Advisor In India
                        </div>
                      </div>
                      <div data-aos-duration="500" data-aos="fade-up"
                           className="fs-6 fw-medium me-0 me-lg-5 my-3 pe-0 pe-lg-5 text-capitalize lh-res-32">
                        {`Equitypandit is india's leading equity research company with client across 32+
                          countries. equitypandit is awarded nationally & internationally for investment
                          research excellence.`}
                      </div>
                      <div className="my-4" data-aos-duration="500" data-aos="fade-up">
                        <button type="button"
                                className="btn btn-danger rounded-1 fs-16 shadow-none fw-semi-bold text-white rounded-pill px-3 py-2">Book
                          An Expert Call
                        </button>
                      </div>
                      <div
                        className="text-warning fs-4 fs-res-16 fw-bold mb-3 pt-3 pt-lg-0 text-capitalize text-center text-lg-start">
                        Awarded Nationally & Internationally By
                      </div>
                      <div className="row d-none">
                        <div className="d-flex gap-5">
                          <div className="">
                            <img src="/ep-wealth/award-8.png" height={40} width={40} className="award-8"
                                 alt='award-8'/>
                          </div>
                          <div className="">
                            <img src="/ep-wealth/award-5.png" height={40} width={100} className="award-5"
                                 alt='award-5'/>
                          </div>
                          <div className="">
                            <img src="/ep-wealth/award-6.png" height={40} width={100} className="award-6"
                                 alt='award-6'/>
                          </div>
                        </div>
                        <div className="d-flex gap-5">
                          <div className="">
                            <img src="/ep-wealth/award-7.png" height={40} width={100} className="award-7"
                                 alt='award-7'/>
                          </div>
                          <div className="">
                            <img src="/ep-wealth/award-4.png" height={40} width={100} className="award-4"
                                 alt='award-4'/>
                          </div>
                          <div className="">
                            <img src="/ep-wealth/award-9.png" height={40} width={100} className="award-9"
                                 alt='award-9'/>
                          </div>
                        </div>
                        <div className="d-flex gap-5">
                          <div className="">
                            <img src="/ep-wealth/award-10.png" height={40} width={100} className="award-10"
                                 alt='award-10'/>
                          </div>
                          <div className="">
                            <img src="/ep-wealth/award-11.png" height={40} width={100} className="award-11"
                                 alt='award-11'/>
                          </div>
                          <div className="">
                            <img src="/ep-wealth/award-12.png" height={40} width={100} className="award-12"
                                 alt='award-12'/>
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center pe-lg-5 pe-0 text-center">
                        <div className="col-4 border-end">
                          <img src="/ep-wealth/award-1.png" className="img-fluid award-8"
                               alt='award-8'/>
                        </div>
                        <div className="col-4 border-end">
                          <img src="/ep-wealth/award-2.png" className="img-fluid award-5"
                               alt='award-5'/>
                        </div>
                        <div className="col-4">
                          <img src="/ep-wealth/award-3.png" className="img-fluid award-6"
                               alt='award-6'/>
                        </div>
                      </div>
                      <hr style={{"margin": "0.3rem 0", "width": "95%", "border": "1px solid #dadada"}}/>
                      <div className="row align-items-center pe-lg-5 pe-0 text-center py-1">
                        <div className="col-4 border-end">
                          <img src="/ep-wealth/award-4.png" className="img-fluid award-7"
                               alt='award-7'/>
                        </div>
                        <div className="col-4 border-end">
                          <img src="/ep-wealth/award-5.png" className="img-fluid award-9"
                               alt='award-9'/>
                        </div>
                        <div className="col-4">
                          <img src="/ep-wealth/award-6.png" className="img-fluid award-10"
                               alt='award-10'/>
                        </div>
                      </div>
                      <hr style={{"width": "95%", "margin": "0.3rem 0", "border": "1px solid #dadada"}}/>
                      <div className="row align-items-center pe-lg-5 pe-0 text-center">
                        <div className="col-4 border-end">
                          <img src="/ep-wealth/award-7.png" className="img-fluid award-11"
                               alt='award-11'/>
                        </div>
                        <div className="col-4 border-end">
                          <img src="/ep-wealth/award-8.png" className="img-fluid award-12"
                               alt='award-12'/>
                        </div>
                        <div className="col-4">
                          <img src="/ep-wealth/award-9.png" className="img-fluid award-12"
                               alt='award-12'/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="d-flex gap-2 mb-0 mb-lg-4 pb-5">
                      <div className="mb-2 ms-1">
                        <img height={64} width={64} src="/ep-wealth/reco-user.png" alt=""/>
                      </div>
                      <div className="ms-2 mt-3 position-absolute ps-4 pt-lg-5">
                        <img className="w-75" src="/ep-wealth/border-line.png"
                             alt=""/>
                      </div>

                      <div className="ms-2 ms-lg-0">
                        <h3 className="font-family-noto-serif fs-27 fs-res-24 fw-semi-bold mb-2 text-black">
                          Recognized</h3>
                        <p className="font-family-noto-sans fs-17 lh-res-32 fs-res-18 fw-medium lh-lg text-capitalize"
                           style={{"color": "#272928"}}>Equitypandit bagged top amends for last wealth
                          advisory</p>
                      </div>
                    </div>
                    <div className="align-items-lg-start d-flex mb-0 mb-lg-4 pb-5 pt-0 pt-lg-4">
                      <div className="mb-2">
                        <img height={64} width={64} className=""
                             src="/ep-wealth/protected-icon.png" alt=""/>
                      </div>
                      <div className="ms-2 mt-4 position-absolute ps-4 pt-lg-5">
                        <img className="w-75 mb-2" src={"/ep-wealth/border-line.png"}
                             alt=""/>
                      </div>
                      <div className="ms-3 ms-lg-2">
                        <h3 className="font-family-noto-serif fs-27 fs-res-24 fw-semi-bold mb-2 text-black">
                          Protected</h3>
                        <p className="font-family-noto-sans fs-17 fs-res-18 fw-medium lh-res-32 lh-lg text-capitalize"
                           style={{"color": "#272928"}}>Equitypandit is a SEBI registered investment advisor
                        </p>
                      </div>
                    </div>
                    <div className="d-flex gap-2 pt-0 pt-lg-4">
                      <div className="mb-2 ms-1">
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        <img className="mb-2" src={"/ep-wealth/trusted-icon.png"} alt=""
                             height={64} width={64} style={{"height": "64px", "width": "64px"}}/>
                      </div>
                      <div className="">
                        <h3 className="font-family-noto-serif fs-27 fs-res-24 fw-semi-bold mb-2 text-black">
                          Trusted</h3>
                        <p className="font-family-noto-sans fs-17 fs-res-18 fw-medium lh-lg text-capitalize lh-res-32"
                           style={{"color": "#272928"}}>Chosen &amp; Trusted by our client over17 years and
                          in
                          32+ countries</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" style={{backgroundImage: `url('/ep-wealth/pipe-bg-img.png')`}}>
        <div className="container">
          <div className="row my-3 gy-md-0 justify-content-center">
            <div className="col-12 col-lg-9">
              <div className="px-2 px-lg-0">
                <div className="lh-base my-3 my-lg-0 text-white">
                  <p data-aos-duration="1000" data-aos="fade-up"
                     className="fs-3 fw-semibold text-start aos-init aos-animate">Above all, We
                    generate</p>
                  <p className="fs-1 fw-bolder text-center text-uppercase aos-init aos-animate" data-aos-duration="1000"
                     data-aos="fade-up">
                    {`"Wealth"`}
                  </p>
                  <p data-aos-duration="1000" data-aos="fade-up"
                     className="fw-semibold fs-3 ps-5 text-end aos-init aos-animate">
                    and
                    consistent returns</p>
                </div>

                <div data-aos-duration="1000" data-aos="fade-up"
                     className="fs-5 lh-7 my-0 my-lg-3 text-center text-white d-none d-lg-block aos-init">
                  The
                  only
                  product you will
                  ever need to
                  achieve your Financial goals and create wealth for your future. EP-Wealth is a
                  well-positioned
                  to deliver highly stable, market-beating returns with 19 years of Research Excellence.
                </div>
                <div data-aos-duration="1000" data-aos="fade-up"
                     className="fs-5 my-0 my-lg-3 text-center text-white pt-3 d-block d-lg-none aos-init aos-animate"
                     style={{"lineHeight": "32px"}}>
                  The
                  only
                  product you will
                  ever need to
                  achieve your Financial goals and create wealth for your future. EP-Wealth is a
                  well-positioned
                  to deliver highly stable, market-beating returns with 19 years of Research Excellence.
                </div>
                <div data-aos-duration="1000" data-aos="fade-up" className="text-center aos-init">
                  <button type="button"
                          className="btn btn-light advisor_btn fs-16 fw-semi-bold my-4 px-4">Connect
                    to Advisor
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="ep_wealth_sec" className="py-5">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div data-aos="fade-up" data-aos-duration="1000"
                   className="fs-1 fw-semibold pb-5 text-black text-center d-none d-lg-block">
                Key
                Highlights of
                <span className="text-danger text-uppercase ms-2">EP-WEALTH</span>
              </div>
              <div data-aos="fade-up" data-aos-duration="1000"
                   className="ms-4 fs-30 fw-semi-bold pb-4 text-black text-center d-block d-lg-none">
                Key
                Highlights of <br/>
                <span className="text-danger text-uppercase ms-2">EP-WEALTH</span><br/>
              </div>
            </div>
          </div>
          <div className="row gx-0 gx-lg-1 gy-1 gy-lg-0">
            <div className="col-12 col-lg-4" data-aos="fade-up" data-aos-duration="1000">
              <div className="key-highlight-card h-100">
                <div className="pt-3 px-4 px-lg-5">
                  <div className="mb-3 pt-1 pt-lg-5">
                    <img className="img-fluid highlight_image" src="/ep-wealth/highlight_image1.png"
                         alt=""/>
                    <img className="img-fluid highlight_pink_image" src="/ep-wealth/highlight_image1.png"
                         alt=""/>
                  </div>
                  <div
                    className="d-flex fw-semibold py-3 hightlight-title-color">
                    Smarter Options Strategies<span className="arrow_icon fs-15 ps-2"><i
                    className="fas fa-arrow-right"></i></span>
                  </div>
                  <div
                    className="hightlight-text-color lh-res-32 pb-4 pb-lg-5 pe-0 pe-lg-5">
                    Our advanced options strategies are tailored to market conditions and
                    designed to help you stay ahead of the curve.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4" data-aos="fade-up" data-aos-duration="2000">
              <div className="key-highlight-card h-100">
                <div className="pt-3 px-4 px-lg-5">
                  <div className="mb-3 pt-1 pt-lg-5">
                    <img className="img-fluid highlight_image" src="/ep-wealth/highlight_image2.png"
                         alt=""/>
                    <img className="img-fluid highlight_pink_image" src="/ep-wealth/highlight_image2.png"
                         alt=""/>
                  </div>
                  <div
                    className="d-flex fw-semibold py-3 hightlight-title-color">
                    Reliable Risk Management<span className="arrow_icon fs-15 ps-2"><i
                    className="fas fa-arrow-right"></i></span>
                  </div>
                  <div
                    className="font-family-noto-sans fs-18 fw-normal pb-4 pb-lg-5 lh-res-32 pe-lg-5 pe-0 hightlight-text-color">
                    Feel confident as your investments are protected from market volatility and
                    other risks with our most reliable risk management systems.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4" data-aos="fade-up" data-aos-duration="3000">
              <div className="key-highlight-card h-100">
                <div className="pt-3 px-4 px-lg-5">
                  <div className="mb-3 pt-1 pt-lg-5">
                    <img className="img-fluid highlight_image" src="/ep-wealth/highlight_image3.png"
                         alt=""/>
                    <img className="img-fluid highlight_pink_image" src="/ep-wealth/highlight_image3.png"
                         alt=""/>
                  </div>
                  <div
                    className="d-flex fw-semibold py-3 hightlight-title-color">
                    Superior Returns<span className="arrow_icon fs-15 ps-2"><i className="fas fa-arrow-right"></i></span>
                  </div>
                  <div
                    className="font-family-noto-sans fs-18 fw-normal pb-4 pb-lg-5 lh-res-32 pe-lg-4 pe-0 hightlight-text-color">
                    Our algorithms and advanced data analytics team of experts help you achieve
                    exceptional returns compared to traditional investments.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='grew_chart_sec' className="grew_chart pb-5">
        <div className="container">
          <div className="row px-2 px-lg-0">
            <div className="col-12 text-center text-lg-start">
              <div data-aos="fade-up" data-aos-duration="1000"
                   className="fs-4 fw-semi-bold lh-base pb-2 text-black d-none d-lg-block">
                With <span className="text-danger">EP-WEALTH</span> ₹10 Lacs Grew To
              </div>
              <div data-aos="fade-up" data-aos-duration="1000"
                   className="fs-5 fw-semi-bold lh-base pb-2 text-black d-block d-lg-none">
                With <span className="text-danger">EP-WEALTH</span> <br/>₹10 Lacs Grew To
              </div>
              <div data-aos="fade-up" data-aos-duration="1000"
                   className="fw-bold text-primary-emphasis d-none d-lg-block">
                <span style={{fontSize: "100px"}}>₹1.94 Crores</span><span className="fs-5"> In Last 7 Years</span>
              </div>
              <div data-aos="fade-up" data-aos-duration="1000"
                   className="fs-1 text-capitalize lh-sm fw-bold pb-3 text-blue d-block d-lg-none">
                ₹1.94 crores <br/>in Last 7 Years
              </div>
              <div data-aos="fade-up" data-aos-duration="1000"
                   className="fs-5 fs-res-18 fw-normal lh-res-32 pb-3 text-black d-none d-lg-block">
                Our investment strategies helped our clients' investment returns to outperform Nifty Returns by
                around 10x and Mutual Funds Returns by around 9x in last 7 years.
              </div>
              <div data-aos="fade-up" data-aos-duration="1000"
                   className="fs-5 fs-res-18 fw-normal lh-res-32 pb-4 text-black d-block d-lg-none">
                Our investment strategies helped our clients' investment returns to outperform Nifty Returns by
                around 10x and Mutual Funds Returns by around 9x in last <br/>7 years.
              </div>
              <p
                className="text-capitalize font-family-noto-sans fs-5 fs-res-18 fw-semi-bold lh-res-32 pb-4 mb-0 text-black">
                ₹10 lacs invested in 2016
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="" data-aos="fade-right" data-aos-duration="1000">
                <canvas id="myChart" style={{"width": "100%", "margin": "0 auto", "height": " 300px"}}></canvas>
                <input className="styled-slider slider-progress bg-gre" id="yearSlider" type="range" min="1" max="7"
                       value="2024" step="1" style={{"width": "100%", "marginTop": "20px"}}/>
                <div id="yearLabels" style={{"display": "flex", "justifyContent": "space-between", "marginTop": "5px"}}>
                </div>
                <div className="text-center fs-12 text-black fw-bold"><span className="">(In
                                Years)</span></div>
                <span id="range-slider__value" style={{"opacity": "0"}}>0</span>
                <div className="pt-0 pt-lg-2 text-center">
                  <a id="nifty_btn" href={{"javascript": void (0)}}
                     className="bg-transparent cp border-0 p-0 p-lg-2 justify-content-evenly text-decoration-none">
                    <div className="border btn rounded-0 " style={{"background": "#0B2471"}}></div>
                    <label className="fw-semi-bold fs-14 text-black text_nifty" htmlFor="nifty_btn">Nifty</label>
                  </a>
                  <a id="mutual_btn" href="javascript:void(0);"
                     className="bg-transparent border-0 cp justify-content-evenly p-0 p-lg-2 ps-3 text-decoration-none">
                    <div className="border btn rounded-0" style={{"background": "#416BF5"}}></div>
                    <label className="fw-semi-bold fs-14 text-black text_mutualfund" htmlFor="mutual_btn">Mutual
                      Funds</label>
                  </a>
                  <a id="epwealth_btn" href="javascript:void(0);"
                     className="bg-transparent border-0 cp justify-content-evenly p-0 p-lg-2 ps-3 text-decoration-none">
                    <div className="border btn rounded-0 bg-primary-pink"></div>
                    <label className="fw-semi-bold fs-14 text-black text_epwealth" htmlFor="epwealth_btn">EP
                      Wealth</label>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="cagr" className="bg-grey cagr py-5">
        <div className="container">
          <div className="row justify-content-center pb-5 px-2 px-lg-0 text-center">
            <div className="col-12 aos-init aos-animate" data-aos-duration="1000" data-aos="fade-up">
              <div
                className="font-family-noto-serif fs-45 fs-res-30 fw-semi-bold text-black pb-3 pb-lg-2 d-none d-lg-block">
                54.76% CAGR
                in Last 7 Years
              </div>
              <div
                className="font-family-noto-serif fs-45 fs-res-30 fw-semi-bold text-black pb-3 pb-lg-2 d-block d-lg-none">
                <span className="text-primary-pink">54.76% CAGR</span>
                <br/>in Last 7 Years
              </div>
            </div>
            <div className="col-12 col-lg-8 aos-init aos-animate" data-aos-duration="1000" data-aos="fade-up">
              <div className="font-family-noto-sans fs-20 fs-res-18 fw-normal lh-res-32 text-black
                            text-capitalize">
                Our award winning investment models with the blend of Nifty and BankNifty Option strategies
                generated positive returns YoY in every market conditions.
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-7 col-12 aos-init aos-animate" data-aos-duration="1000" data-aos="fade-up">
              <div className="blue-bg-returns px-0 px-lg-3 py-4 py-lg-5 rounded-4">
                <h4
                  className="font-family-noto-serif fs-res-18 mb-3 fw-semi-bold text-capitalize text-center text-white">
                  EP Wealth Returns in Last 7 Years</h4>
                {/*<Bar data={{*/}
                {/*           labels: ["2016", "2017", "2018", "2019", "2020", "2021", "2022"],*/}
                {/*           datasets:[{*/}
                {/*             label: "EP Wealth Returns",*/}
                {/*             backgroundColor: "#FFFFFF",*/}
                {/*             borderWidth: 0,*/}
                {/*             data:[]*/}
                {/*           }]*/}
                {/*         }}*/}
                {/*/>*/}
                {/*<canvas id="returns" height="280" className="px-2 px-lg-0"*/}
                {/*        style={{*/}
                {/*          "width": "714px",*/}
                {/*          "margin": "0px auto",*/}
                {/*          "display": "block",*/}
                {/*          "boxSizing": "border-box",*/}
                {/*          "height": "350px"*/}
                {/*        }}*/}
                {/*        width="571"></canvas>*/}
                {/*<div className="pt-2 pt-lg-4 text-center">*/}
                {/*  <a id="nifty_btn" href={{"javascript": "void(0)"}}*/}
                {/*     className="bg-transparent cp border-0 p-2 justify-content-evenly text-decoration-none">*/}
                {/*    <div className="border btn rounded-0 bg-white"></div>*/}
                {/*    <label className="fw-medium fs-14 text-white text_nifty" htmlFor="nifty_btn">EP Wealth*/}
                {/*      Returns</label>*/}
                {/*  </a>*/}
                {/*</div>*/}

              </div>
            </div>
            <div className="col-lg-5 col-12 aos-init aos-animate" data-aos-duration="1000" data-aos="fade-up">
              <div style={{"border": "0.5px solid #CCCFE9"}}
                   className="bg-white mt-4 mt-lg-0 px-2 py-4 py-lg-2 rounded-4 ">
                <h4 className="font-family-noto-serif fs-18 mb-3 fw-semi-bold text-center">CAGR In Last 7 Years</h4>
                <canvas id="chart_cagr"
                        style={{"width": "507px", "height": "229px", "display": "block", "boxSizing": "border-box"}}
                        width="406" height="183"></canvas>
                <div className="pt-2 text-center">
                  <a id="nifty_btn" href={{"javascript": "void(0)"}}
                     className="bg-transparent cp border-0 p-0 p-lg-2 justify-content-evenly text-decoration-none">
                    <div className="border btn rounded-0 " style={{"background": "#0B2471"}}></div>
                    <label className="fw-semi-bold fs-14 text-black text_nifty" htmlFor="nifty_btn">Nifty</label>
                  </a>
                  <a id="mutual_btn" href={{"javascript": "void(0)"}}
                     className="bg-transparent border-0 cp justify-content-evenly p-0 p-lg-2 ps-3 text-decoration-none">
                    <div className="border btn rounded-0" style={{"background": "#416BF5"}}></div>
                    <label className="fw-semi-bold fs-14 text-black text_mutualfund" htmlFor="mutual_btn">Mutual
                      Funds</label>
                  </a>
                  <a id="epwealth_btn" href={{"javascript": "void(0)"}}
                     className="bg-transparent border-0 cp justify-content-evenly p-0 p-lg-2 ps-3 text-decoration-none">
                    <div className="border btn rounded-0 bg-primary-pink"></div>
                    <label className="fw-semi-bold fs-14 text-black text_epwealth" htmlFor="epwealth_btn">EP
                      Wealth</label>
                  </a>
                </div>
              </div>
              <div style={{"border": "0.5px solid #CCCFE9"}} className="bg-white mt-4 px-2 py-4 py-lg-2 rounded-4">
                <h4 className="font-family-noto-serif fs-18 mb-3 fw-semi-bold text-center">CAGR In Last 7 Years</h4>
                <div>
                  <canvas id="cagr_line_chart"
                          style={{
                            "display": "block",
                            "boxSizing": "border-box",
                            "height": "150px",
                            "width": "507px"
                          }}></canvas>
                </div>
                <div className="pt-2 text-center">
                  <a id="nifty_btn" href={{"javascript": "void(0)"}}
                     className="bg-transparent cp border-0 p-0 p-lg-2 justify-content-evenly text-decoration-none">
                    <div className="border btn rounded-0 " style={{"background": "#0B2471"}}></div>
                    <label className="fw-semi-bold fs-14 text-black text_nifty" htmlFor="nifty_btn">Nifty</label>
                  </a>
                  <a id="mutual_btn" href={{"javascript": "void(0)"}}
                     className="bg-transparent border-0 cp justify-content-evenly p-0 p-lg-2 ps-3 text-decoration-none">
                    <div className="border btn rounded-0" style={{"background": "#416BF5"}}></div>
                    <label className="fw-semi-bold fs-14 text-black text_mutualfund" htmlFor="mutual_btn">Mutual
                      Funds</label>
                  </a>
                  <a id="epwealth_btn" href={{"javascript": "void(0)"}}
                     className="bg-transparent border-0 cp justify-content-evenly p-0 p-lg-2 ps-3 text-decoration-none">
                    <div className="border btn rounded-0 bg-primary-pink"></div>
                    <label className="fw-semi-bold fs-14 text-black text_epwealth" htmlFor="epwealth_btn">EP
                      Wealth</label>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr style={{"margin": "0.3rem 0", "border": "1px solid #dadada"}}/>

      <div className="container mb-5">
        <div className="row">
          <div className="col-md-8">
            <div className="">
              <h1 className="fw-bold text-uppercase">
                HNI & UHNI
              </h1>
              <small className="">Suitable Risk Profile </small>
              <small className="bg-success bg-opacity-50 px-2 py-1 rounded-3">Aggressive</small>
              <p className="mt-3 fs-18">
                {`Ep Index Options is designed to cater to traders of all experience levels. Whether you're a seasoned pro or just starting out, our index options recommendations will help you navigate the complex world of options trading with ease. Our team of analysts is always monitoring the market, keeping an eye out for the latest trends and shifts in market sentiment with on our cutting-edge algorithms and market analysis.`}
              </p>
              <hr className="my-4"/>
              <h3 className="fw-bold">What you get?</h3>
              <ul className="list-unstyled">
                <li className="my-2 fs-18"><CheckIcon/> Herein we will provide you Intraday recommendations in Index
                  Options.
                </li>
                <li className="my-2 fs-18"><CheckIcon/> Follow Ups & All Important News & Information.</li>
                <li className="my-2 fs-18"><CheckIcon/> Nifty Review, Resistance & Support.</li>
                <li className="my-2 fs-18"><CheckIcon/> Pre-Market trend of Sensex and Nifty.</li>
              </ul>
              <hr className="my-4"/>
              <h3 className="fw-bold">Trader Profile: Aggressive</h3>
              <ul className="list-unstyled">
                <li className="my-2 fs-18"><CheckIcon/> Full time / Part time traders can subscribe this package.</li>
                <li className="my-2 fs-18"><CheckIcon/> Should execute all the trades on time.</li>
                <li className="my-2 fs-18"><CheckIcon/> Should have access to Email / Phone.</li>
                <li className="my-2 fs-18"><CheckIcon/> Minimum Investment Suggested: Rs.30,000.</li>
              </ul>
              <hr className="my-4"/>
              <h3 className="fw-bold">Terms of Agreement:</h3>
              <ul className="list-unstyled">
                <li className="my-2 fs-18"><CheckIcon/> Full time / Part time traders can subscribe this package.</li>
                <li className="my-2 fs-18"><CheckIcon/> Should execute all the trades on time.</li>
                <li className="my-2 fs-18"><CheckIcon/> Should have access to Email / Phone.</li>
                <li className="my-2 fs-18"><CheckIcon/> Minimum Investment Suggested: Rs.30,000.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="sticky-top text-center border bg-white pb-3 z-0 " style={{top: "15%"}}>
              <div className="py-3"
                   style={{backgroundImage: `url('/assets/img/card_header.svg')`, backgroundRepeat: "no-repeat"}}>
                <h4 className="text-uppercase fw-bold">HNI & UHNI</h4>
                <p className="pb-0 fw-normal">Go Premium Get Awesome</p>

              </div>
              <div className="d-flex justify-content-center mt-3">
                <span className="badge bg-secondary-subtle rounded-2 fw-bold text-black">DERIVATIVES</span>
                <span
                  className="badge ms-3 bg-primary-subtle rounded-2 fw-bold text-black text-uppercase">intraday</span>
              </div>
              <div className="mt-3 mb-2 fw-400 fs-16 text-black">
                Medium Plan |
                6 Months
              </div>
              <div className="fw-bold mb-2 fs-16 text-muted">
                {/*<s>₹45,000/-</s>*/}
                {/*<small className="fs-12"><s>+GST</s></small>*/}
              </div>
              <div className="fw-bold mb-3 fs-22 text-black">
                ₹50,000/-
                <small className="fs-12">+GST</small>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <button className="btn btn-sm btn-success rounded-2">Pay Now</button>
                <button className="btn btn-sm btn-outline-success rounded-2 ms-3">Contact Us</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TradingInvestingFAQ/>
      {/* ----------Contact Us Section----------- */}
      <section className="bg-green bg-opacity-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center py-5">
                <h1 className="fw-bold">
                  Need any help? Contact To Support Team!
                </h1>
                <p>
                  We are always there for you every time and will guide you at
                  every step. <br />
                  The team of certified advisors will assist you in achieving
                  the wealth creation goal.
                </p>
                <div className="d-flex text-success justify-content-center fw-bold">
                  <p>+91 8000 816 688</p>
                  <p className="mx-2"> | </p>
                  <p>support@equitypandit.com</p>
                </div>
                <button
                  className="btn btn-dark"
                  onClick={() => handleRedirect("/contact-us")}
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-success bg-gradient d-none">
        <div className="container my-5">
          <div className="row py-5">
            <div className="col-md-12 text-center">
              <div className="bg-light rounded-3 px-3 py-5 m-2">
                <h1 className="fw-bold">
                  Take a step ahead & Invest Now with EquityPandit
                </h1>
                <h4 className="my-3" style={{ lineHeight: "1.5" }}>
                  For assistance, please call us at <b>+91 8000 816688</b> or
                  email us at <b>support@equitypandit.com</b>
                </h4>
                <button
                  className="btn btn-outline-success"
                  onClick={() => handleRedirect("/get-started")}
                >
                  Get Started <MoveRight size={16} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ----------Contact Us Section----------- */}
    </Layout>
  );
};

export default HniUhni;
