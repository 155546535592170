import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
  fetchUserDetailService,
  loginService,
  logoutService,
  updateUserDetailService,
} from "../../pages/auth/service/get-started.service";
import axios from "axios";
import {V1_API_ORIGIN} from "../../config/constants.config";

const accessToken =  sessionStorage.getItem('suat') || false; // secure user auth token
const reFreshToken = sessionStorage.getItem('rtust') || false; // Refresh token to update user secure token
const uid = sessionStorage.getItem('uid') || false; // user ID
const mobile = sessionStorage.getItem('mobile') || false; // user ID

const initialState = {
  authState: !!accessToken,
  accessToken,
  reFreshToken,
  uid,
  mobile,
  otpError: false,
  loginStep: !!accessToken ? "complete" : "mobile-initial",
  userDetail: {
    source: "ep_co_in",
    email: false,
    rpfProfileType: "filled",
  }
};

export const reduxLogin = createAsyncThunk("auth/login", (payload) => {
  return loginService(payload)
});

export const reduxOtpVerify = createAsyncThunk("auth/otp", async (params, {rejectWithValue}) => {
  try {
    let payload = {...params};
    return await axios.post(`${V1_API_ORIGIN}/users/otp-verify`, payload);
  } catch (error) {
    if (error.response) {
      if (error.response.status === 409) {
        return rejectWithValue({status: 409, data: error.response.data});
      }
      return rejectWithValue({status: error.response.status, data: error.response.data});
    } else if (error.request) {
      return rejectWithValue({status: 'no_response', data: error.message});
    } else {
      return rejectWithValue({status: 'network_error', data: error.message});
    }
  }
});

export const reduxLogout = createAsyncThunk("auth/logout", (payload) => {
  return logoutService(payload)
});

export const reduxFetchUserDetail = createAsyncThunk("auth/fetchUserDetail", async (payload) => {
  return fetchUserDetailService(payload);
});

export const reduxUpdateUserDetail = createAsyncThunk("auth/updateUserDetail", (payload, {rejectWithValue}) => {
  return updateUserDetailService(payload)
    .catch(e => {
      return rejectWithValue(e.response.data.message);
    })
});

export const refreshToken  = createAsyncThunk("auth/refreshToken", (payload) => {
  return {};
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearSession: (state, action) => {
      if ((typeof window !== 'undefined')) {
        sessionStorage.removeItem("suat")
        sessionStorage.removeItem("rtust")
        sessionStorage.removeItem("uid")
      }
    },
    guestSource: (state, action) => {
      state.userDetail.source = action.payload;
    },
    changeLoginStep: (state, action) => {
      state.loginStep = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(reduxLogin.pending, (state, action) => {
      state.mobile = action.meta.arg.mobile;
      if (typeof window !== 'undefined') {
        sessionStorage.setItem('mobile', action.meta.arg.mobile)
      }
    });
    builder.addCase(reduxLogin.fulfilled, (state, action) => {
      state.loginStep = 'otp';
    });
    builder.addCase(reduxLogin.rejected, (state, action) => {
    });
    // ----------------------------------------------------------
    builder.addCase(reduxOtpVerify.pending, state => {
    });
    builder.addCase(reduxOtpVerify.fulfilled, (state, action) => {
      if (typeof window !== 'undefined') {
        sessionStorage.setItem('suat', action.payload.data.data.authentication.accessToken);
        sessionStorage.setItem('rtust', action.payload.data.data.authentication.refreshToken);
        sessionStorage.setItem('uid', action.payload.data.data.id);
      }
      state.authState = !!action.payload.data.data.authentication.accessToken
      state.loginStep = !action.payload.data.data.email ? 'details' : 'successfully_logged_in';
      state.userDetail = action.payload.data.data;
    });
    builder.addCase(reduxOtpVerify.rejected, (state, action) => {
      state.otpError = action.payload.data.message;
    });
    // ----------------------------------------------------------
    builder.addCase(reduxFetchUserDetail.pending, state => {
    });
    builder.addCase(reduxFetchUserDetail.fulfilled, (state, action) => {
      state.userDetail = action.payload.data.data;
    });
    builder.addCase(reduxFetchUserDetail.rejected, (state, action) => {
    });
    // ----------------------------------------------------------
    builder.addCase(reduxUpdateUserDetail.pending, state => {
    });
    builder.addCase(reduxUpdateUserDetail.fulfilled, (state, action) => {
      state.loginStep = 'successfully_logged_in';
      state.userDetail = action.payload.data.data;
    });
    builder.addCase(reduxUpdateUserDetail.rejected, (state, action) => {
      state.detailsErrorMessage = action.payload;
    });
    // ----------------------------------------------------------
    builder.addCase(reduxLogout.pending, state => {
    });
    builder.addCase(reduxLogout.fulfilled, (state, action) => {
      state.accessToken = null
      state.reFreshToken = null
      state.authState = false
      state.userDetail = {}
      state.loginStep = 'mobile'
    });
    builder.addCase(reduxLogout.rejected, (state, action) => {
    });
    // ---------------------------------------------------------------
    builder.addCase(refreshToken.pending, state => {
      state.status = "pending";
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.status = "complete";
      // state.accessToken = action.payload?.data.accessToken;
      // state.reFreshToken = action.payload?.data.refreshToken;
      state.error = action.error?.message;
    });
    builder.addCase(refreshToken.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error?.message;
    });
  }
});

export const {clearSession, changeLoginStep} = authSlice.actions;
export const authReducer = authSlice.reducer;
