import axios from "axios";
import {V1_API_ORIGIN} from "../../../config/constants.config";

export const getPackagesService = () => {
  return axios.get(`${V1_API_ORIGIN}/packages/get-packages`);
}

export const requestSignDocument = (id, selectedPlan, values) => {
  const userId = sessionStorage.getItem('uid')
  return axios.get(`${V1_API_ORIGIN}/e-sign/sign-document/${id}`, {
    params: {selectedPlan, ...values, userId}
  });
}

export const cancelSignDocument = (id) => {
  return axios.post(`${V1_API_ORIGIN}/e-sign/cancel-document-sign`, {
    doc_id: id
  });
}

export const getBundleService = (id) => {
  return axios.get(`${V1_API_ORIGIN}/bundles?showHide=true`)
    .then(response => {
      return response.data.data.map((item, index) => {
        delete item.packages
        return item
      });
    });
}

export const digioSignedUpdatePayment = (data) => {
  return axios.post(`${V1_API_ORIGIN}/payments/update-digio`, {
    ...data
  });
}

export const getKYCDetails = () => {
    const uid = sessionStorage.getItem('uid');
    return axios.get(`${V1_API_ORIGIN}/kyc/get-by-userId/${uid}`);
}

const loadedScripts = new Set();

function loadScript(src) {
  return new Promise((resolve, reject) => {
    if (loadedScripts.has(src)) {
      resolve(true); // Script already loaded
      return;
    }

    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      loadedScripts.add(src);
      resolve(true);
    };
    script.onerror = () => {
      reject(new Error(`Failed to load script: ${src}`));
    };
    document.body.appendChild(script);
  });
}

export const paymentRequest = async () => {
  const res = await loadScript(
    "https://checkout.razorpay.com/v1/checkout.js"
  );

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
  const uid = sessionStorage.getItem('uid');
  return axios.get(`${V1_API_ORIGIN}/payments/payment-request/${uid}`);
}

export const updateRazorpaySuccess = (payload) => {
  return axios.post(`${V1_API_ORIGIN}/payments/update-payment-on-razorpay-success/${window.paymentID}`, payload)
    .then(() => {
      axios.get(`${V1_API_ORIGIN}/payments/${window.paymentID}`)
        .then(r => {
          axios.post(`${V1_API_ORIGIN}/payments/${window.paymentID}`, {
            payStatus: r.data.data.payStatus
          })
        })
    });
}

export const submitLead = (payload) => {
  return axios.post(`${V1_API_ORIGIN}/users/register`, payload)
}
