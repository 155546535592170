import Layout from "../../layout/interface/Layout";
import Mobile from "../../layout/interface/guest/mobile";
import Otp from "../../layout/interface/guest/otp";
import Detail from "../../layout/interface/guest/detail";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function GetStarted() {
  const section = useSelector((state) => state.auth.loginStep);
  const userDetail = useSelector((state) => state.auth.userDetail);

  useEffect(() => {
  if(section === "successfully_logged_in") {
      window.location.href = "/dashboard"
    }
  }, [section]);

  const loadSection = () => {
    if (["mobile-initial", "mobile"].includes(section))
      return <Mobile/>
    else if (section === "otp")
      return <Otp/>
    else if (section === "details" || !userDetail.email)
      return <Detail/>
  }

  return (
    <Layout>
    <div>
      <section id="hero"
               className="d-flex align-items-center justify-content-center flex-column pt-lg-0 pt-3">
        <div className="container">
          <div className="row gy-lg-4 justify-content-center">
            <div className="col-lg-6 d-flex flex-column text-center text-md-start">
              <h1 style={{"letterSpacing": "2px"}}
                  className="pb-lg-3 pb-4 pt-lg-5 pt-md-0 lh-base fw-bold text-black fs_res_32">Get
                your
                financial plan for FREE!</h1>
              <p className="d-lg-block d-none" style={{"letterSpacing": "1px"}}>Our Methodical Risk
                Profiling Process Is Tailored to Determine the Ideal Investment Risk Level That
                Aligns
                Perfectly with Your Financial Ambitions. Trust Us to Balance Your Risk Needs,
                Capacity,
                and Personal Tolerance, Crafting a Customized Strategy for Your Long-Term
                Success.</p>
            </div>
            <div className="col-lg-4 offset-lg-1">
              <div className="card text-center p-3 mt-lg-5">
                <small className="text-center" style={{"letterSpacing": "1px"}}>
                  Get Your Risk Profile
                </small>
                <h4 style={{"letterSpacing": "1px"}} className="fw-bold text-center">
                  Just in a Few Minutes!
                </h4>
                {loadSection('mobile')}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-green" style={{marginTop: "150px"}}>
        <div className="container py-3 text-center d-flex justify-content-center">
          <span className="text-dark">We Are Happy To Help You!</span>
          <a href="tel:+918000816688" className="mx-lg-4 mx-2 text-decoration-none">
            <span className="text-dark">+918000816688</span>
          </a>
          <a href="mailto:support@equitypandit.com" className="text-decoration-none">
            <span className="text-dark">support@equitypandit.com</span>
          </a>
        </div>
      </section>
      <section className="text-center" style={{marginTop: "70px"}}>
        <h1 className="fw-bold">A Financial Plan That Work Perfectly</h1>
      </section>
    </div>
    </Layout>
  )
}
