import {
  InfoIcon,
  Check,
  MoveRight,
  ChartCandlestick,
  Landmark,
  BadgeIndianRupee,
} from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { APP_URL } from "../../../config/constants.config";
import Layout from "../../layout/interface/Layout";
import {handleScrollToTop, useDocumentTitle} from "../../../components/Common";

const Welcome = () => {
  const router = useNavigate();
  function handleRedirect(link) {
    router(link);
    handleScrollToTop();
  }
  useDocumentTitle('EquityPandit Advisory');
  return (
    <Layout>
      {/* ----------Home Section----------- */}
      <section className="">
        <div className="container-fluid my-5">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-7 col-12 text-center text-md-start">
              <div
                id="carouselExample"
                className="carousel carousel-dark slide"
              >
                <div className="carousel-inner ">
                  <div className="carousel-item active ps-md-4">
                    <div className="mx-md-5 px-md-4">
                      <p className="mb-0" style={{ letterSpacing: 1.5 }}>
                        <img
                          className="pe-2"
                          style={{ width: "35px" }}
                          src={`${APP_URL}/assets/img/awarded-as.png`}
                          alt="support_image"
                        />
                        AWARDED AS
                      </p>
                      <h1 className="fw-bold" style={{ fontSize: "2.7rem" }}>
                        {`India’s Leading Equity Research Company`}
                      </h1>
                      <div className="d-flex align-items-center justify-content-center justify-content-md-end py-3">
                        <p className="mb-0 px-2 text-end fw-bold">
                          <i className="me-md-4">- By World Signature Awards</i>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* ---------- */}
                  <div className="carousel-item ps-md-4">
                    <div className="mx-md-5 px-md-4">
                      <p className="mb-0" style={{ letterSpacing: 1.5 }}>
                        <img
                          className="pe-2"
                          style={{ width: "35px" }}
                          src={`${APP_URL}/assets/img/awarded-as.png`}
                          alt="support_image"
                        />
                        AWARDED AS
                      </p>
                      <h1 className="fw-bold" style={{ fontSize: "2.7rem" }}>
                        {`Most Innovative Company in BFSI`}
                      </h1>
                      <div className="d-flex align-items-center justify-content-center justify-content-md-end py-3">
                        <p className="mb-0 px-2 text-end fw-bold">
                          <i className="me-md-4">- By The Economic Times</i>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* ---------- */}
                  <div className="carousel-item ps-md-4">
                    <div className="mx-md-5 px-md-4">
                      <p className="mb-0" style={{ letterSpacing: 1.5 }}>
                        <img
                          className="pe-2"
                          style={{ width: "35px" }}
                          src={`${APP_URL}/assets/img/awarded-as.png`}
                          alt="support_image"
                        />
                        AWARDED AS
                      </p>
                      <h1 className="fw-bold" style={{ fontSize: "2.7rem" }}>
                        {`Fastest Growing Market Research Company in India`}
                      </h1>
                      <div className="d-flex align-items-center justify-content-center justify-content-md-end py-3">
                        <p className="mb-0 px-2 text-end fw-bold">
                          <i className="me-md-4">- By CEO Magazine</i>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* ---------- */}
                  <div className="carousel-item ps-md-4">
                    <div className="mx-md-5 px-md-4">
                      <p className="mb-0" style={{ letterSpacing: 1.5 }}>
                        <img
                          className="pe-2"
                          style={{ width: "35px" }}
                          src={`${APP_URL}/assets/img/awarded-as.png`}
                          alt="support_image"
                        />
                        AWARDED AS
                      </p>
                      <h1 className="fw-bold" style={{ fontSize: "2.7rem" }}>
                        {`Highly Recommended Financial Advisor For The Year`}
                      </h1>
                      <div className="d-flex align-items-center justify-content-center justify-content-md-end py-3">
                        <p className="mb-0 px-2 text-end fw-bold">
                          <i className="me-md-4">- By Enterprise World</i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExample"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    style={{ opacity: "0.1" }}
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExample"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    style={{ opacity: "0.1" }}
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>

              <div className="mt-3 px-md-5 ms-md-4">
                <div
                  className="btn btn-md btn-dark text-white uni-cursor-pointer ms-md-4"
                  onClick={() => handleRedirect("/services")}
                >
                  Explore Services <MoveRight size={16} />
                </div>
              </div>
            </div>
            <div className="col-md-5 col-10 text-center">
              <img
                className="img-fluid"
                style={{width: "75%"}}
                src={`${APP_URL}/assets/img/home-section.png`}
                alt="support_image"
              />
            </div>
          </div>
        </div>
      </section>
      {/* ----------Home Section----------- */}

      {/* ----------Featured Section----------- */}
      <section className="bg-green bg-opacity-10">
        <div className="container py-3">
          <div className="row g-4 align-items-center d-flex flex-nowrap overflow-auto">
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-8 text-center text-md-start">
              <h4 className="fw-bold text-dark">Featured By</h4>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-8">
              <img
                style={{ width: "100%" }}
                src={`${APP_URL}/assets/img/feature1.png`}
                alt="support_image"
              />
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-8">
              <img
                style={{ width: "100%" }}
                src={`${APP_URL}/assets/img/feature2.png`}
                alt="support_image"
              />
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-8">
              <img
                style={{ width: "100%" }}
                src={`${APP_URL}/assets/img/feature3.png`}
                alt="support_image"
              />
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-8">
              <img
                style={{ width: "100%" }}
                src={`${APP_URL}/assets/img/feature4.png`}
                alt="support_image"
              />
            </div>
          </div>
        </div>
      </section>
      {/* ----------Featured Section----------- */}

      <section className="">
        <div className="container  py-5">
          <div className="row align-items-center my-5">
            <div className="col-md-6 d-none d-md-block text-center">
              <img
                className="rounded-3 shadow"
                src={`${APP_URL}/assets/img/man.png`}
                alt="support_image"
                style={{ width: "85%" }}
              />
            </div>
            <div className="col-md-6 text-center text-md-start">
              <h1
                className="fw-bold text-success"
                style={{ fontSize: "3.8rem" }}
              >
                10,00,000+
              </h1>
              <h1 className="fw-bold mb-5">
                Investors & Traders Use Equitypandit
              </h1>
              <div className="row g-3 align-items-center">
                <div className="col-md-5 col-6">
                  <div className="rounded-3 p-3 pt-5  bg-green text-end shadow-sm">
                    <h3 className="mb-0 fw-bold">1M+</h3>
                    <small>Users</small>
                  </div>
                </div>
                <div className="col-md-5 col-6">
                  <div className="rounded-3 p-3 pt-5  bg-success bg-opacity-10 text-end shadow-sm">
                    <h3 className="mb-0 fw-bold">19+</h3>
                    <small>Years of experience</small>
                  </div>
                </div>
              </div>
              <div className="row g-3 mt-1">
                <div className="col-md-5 col-6">
                  <div className="rounded-3 p-3 pt-5 bg-success bg-opacity-10 text-end shadow-sm">
                    <h3 className="mb-0 fw-bold">5 Lacs+</h3>
                    <small>App Downloads</small>
                  </div>
                </div>
                <div className="col-md-5 col-6">
                  <div className="rounded-3 p-3 pt-5 bg-green text-end shadow-sm">
                    <h3 className="mb-0 fw-bold">24x7</h3>
                    <small>Help & Support</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ----------Counter Section----------- */}
      <section>
        <div className="container my-5 d-none">
          <div className="row g-4 align-items-center">
            <div className="col-md-6 order-2 order-md-1">
              <img
                alt="about"
                style={{ width: "100%" }}
                src={`${APP_URL}/assets/img/about-ep.png`}
              />
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="px-md-4">
                <h1 className="fw-bold mb-5 text-center text-md-start">
                  Defining the future of online experiences!
                </h1>
                <p>
                  Embrace a new era of digital success with Mizzle. Our team
                  combines cutting-edge design with robust development to
                  deliver websites that captivate and convert.
                </p>
                <p>
                  <Check size={20} className="text-success" /> Personalized
                  Financial Assessment
                </p>
                <p>
                  <Check size={20} className="text-success" /> Personalized
                  Financial Assessment
                </p>
                <p>
                  <Check size={20} className="text-success" /> Personalized
                  Financial Assessment
                </p>
                <button className="btn btn-md btn-success">
                  Get Started <MoveRight size={16} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ----------Counter Section----------- */}

      {/* ----------Service Section----------- */}
      <section className="border-top bg-green">
        <div className="container my-5 pb-5">
          <div className="row g-4 py-5">
            <div className="col-md-12">
              <h1 className="fw-bold mb-3 text-center">
                Personalized Solutions For All Your Financial Needs
              </h1>
            </div>
            <div className="col-md-4">
              <div className="rounded-3 p-3 bg-white shadow">
                {/* <InfoIcon size={40} className="text-success" /> */}
                <h2 className="my-3 fw-bold text-center">
                  Investing & Trading <br />
                  Services
                </h2>
                <div className="text-center my-5">
                  <ChartCandlestick className="text-success" size={100} />
                </div>
                {/* <img
                  className="my-3"
                  src={`${APP_URL}/assets/img/support.png`}
                  alt="support_image"
                  style={{ width: "100%" }}
                /> */}
                <p>
                  Specialized plans to help you make better returns from your
                  trading & investing journey.
                </p>
                <p>
                  <Check size={20} /> Personalized Financial Assessment
                </p>
                <p>
                  <Check size={20} /> Personalized Financial Assessment
                </p>
                <p>
                  <Check size={20} /> Personalized Financial Assessment
                </p>
                <button
                  className="mt-3 btn btn-md btn-dark"
                  onClick={() => handleRedirect("services/trading-investing")}
                >
                  Explore Now <MoveRight size={16} />
                </button>
              </div>
            </div>
            <div className="col-md-4">
              <div className="rounded-3 p-3 bg-white shadow">
                <h2 className="my-3 fw-bold  text-center">
                  Institutional Investors
                  <br /> & Family Offices
                </h2>
                <div className="text-center my-5">
                  <Landmark size={100} className="text-success" />
                </div>
                {/* <img
                  className="my-3"
                  src={`${APP_URL}/assets/img/support.png`}
                  alt="support_image"
                  style={{ width: "100%" }}
                /> */}
                <p>
                  We deliver scalable, customized investment solutions for
                  institutional investors that prioritize risk management,
                  compliance, and superior performance.
                </p>
                <p>
                  <Check size={20} /> Personalized Financial Assessment
                </p>
                <p>
                  <Check size={20} /> Personalized Financial Assessment
                </p>
                <p>
                  <Check size={20} /> Personalized Financial Assessment
                </p>
                <button
                  className="mt-3 btn btn-md btn-dark"
                  onClick={() =>
                    handleRedirect("services/institutional-investors")
                  }
                >
                  Explore Now
                  <MoveRight size={16} />
                </button>
              </div>
            </div>
            <div className="col-md-4">
              <div className="rounded-3 p-3 bg-white shadow">
                {/* <InfoIcon size={40} className="text-success" /> */}
                <h2 className="my-3 fw-bold  text-center">
                  HNI & Ultra HNI <br />
                  Services
                </h2>
                <div className="text-center my-5">
                  <BadgeIndianRupee size={100} className="text-success" />
                </div>
                {/* <img
                  className="my-3"
                  src={`${APP_URL}/assets/img/support.png`}
                  alt="support_image"
                  style={{ width: "100%" }}
                /> */}
                <p>
                  Achieve your financial goals with confidence. Our specialized
                  services deliver exceptional results and peace of mind.
                </p>
                <p>
                  <Check size={20} /> Personalized Financial Assessment
                </p>
                <p>
                  <Check size={20} /> Personalized Financial Assessment
                </p>
                <p>
                  <Check size={20} /> Personalized Financial Assessment
                </p>

                <button
                  className="mt-3 btn btn-md btn-dark"
                  onClick={() => handleRedirect("services/hni-uhni")}
                >
                  Explore Now <MoveRight size={16} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ----------Service Section----------- */}

      {/* ----------Get Started Section----------- */}
      <section className="">
        <div className="container my-5">
          <div className="row bg-success bg-opacity-10 rounded-3 align-items-center p-3">
            <div className="col-md-5 ">
              <div className="h-100 d-flex flex-column">
                <h1 className="mb-4 fw-bold">
                  Your New Wealth Building Journey will start in just 4 Easy
                  Steps
                </h1>

                <div className="mt-auto">
                  <a
                    className="btn btn-success"
                    href="https://www.youtube.com/embed/tXHviS-4ygo"
                    target="_blank"
                    data-glightbox=""
                    data-gallery="course-video"
                  >
                    Start Building Wealth Now <MoveRight size={16} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="row g-4 py-4">
                <div className="col-md-6">
                  <div className="p-0 p-md-3">
                    <div
                      className="bg-success bg-opacity-25"
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      1
                    </div>

                    <h3 className="fw-bold mt-3">Risk Profiling</h3>
                    <p>
                      Think of it as choosing the right spice level for your
                      meal. We evaluate factors like your financial goals, time
                      horizon, and tolerance for market fluctuations to create a
                      personalized investment strategy that aligns with your
                      risk appetite.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="p-0 p-md-3">
                    <div
                      className="bg-success bg-opacity-25"
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      2
                    </div>
                    <h3 className="fw-bold mt-3">Select Your Service</h3>
                    <p>
                      Choose the investment service that best aligns with your
                      unique financial goals and risk tolerance. Whether{" "}
                      {"you're"}
                      seeking trading or investing plans, HNI or UHNI services,
                      Institutional Services we offer a range of tailored
                      solutions to meet your needs.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="p-0 p-md-3">
                    <div
                      className="bg-success bg-opacity-25"
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      3
                    </div>
                    <h3 className="fw-bold mt-3">Make Payment</h3>
                    <p>
                      Once you select the service, make the payment and you’ll
                      be ready to get alerts for your chosen services.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="p-0 p-md-3">
                    <div
                      className="bg-success bg-opacity-25"
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      4
                    </div>
                    <h3 className="fw-bold mt-3">Start Receiving Alerts</h3>
                    <p>
                      Start Receiving Alerts: Get real-time notifications on
                      your dedicated app, keeping you informed about investment
                      advice, updated and much more.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-3">
              <InfoIcon size={40} className="text-light my-1" />

              <div className="px-3 py-5">
                <h4 className="fw-bold mt-3">1. Risk Profiling</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="bg-light rounded-3 px-3 py-5">
                <h4 className="fw-bold">2. Select Service</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="bg-light rounded-3 px-3 py-5">
                <h4 className="fw-bold">3. Fill up your details</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="bg-light rounded-3 px-3 py-5">
                <h4 className="fw-bold">4. Start receiving alerts</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* ----------Get Started Section----------- */}

      {/* ----------Support Section----------- */}
      <section className="">
        <div className="container my-5">
          <div className="row g-3 py-5">
            <div className="col-md-12 text-center">
              <h4 className="mb-1">24×7 Available For You</h4>
              <h1 className="fw-bold mb-4">Support and Help Desk</h1>
            </div>
            {/* ----- */}
            <div className="col-md-3 ">
              <div className="bg-secondary-subtle rounded-3 my-3 px-3 py-5 text-center">
                <img
                  src={`${APP_URL}/assets/img/support.png`}
                  alt="support_image"
                  width={250}
                  height={150}
                />
              </div>
              <div className="text-center text-md-start">
                <h4 className="fw-bold">24 × 7 Support</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
            {/* ----- */}
            <div className="col-md-3">
              <div className="text-center bg-secondary-subtle rounded-3 my-3 px-3 py-5">
                <img
                  src={`${APP_URL}/assets/img/support.png`}
                  alt="support_image"
                  width={250}
                  height={150}
                />
              </div>
              <div className="text-center text-md-start">
                <h4 className="fw-bold">Timely Alerts</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
            {/* ----- */}
            <div className="col-md-3">
              <div className="text-center bg-secondary-subtle rounded-3 my-3 px-3 py-5">
                <img
                  src={`${APP_URL}/assets/img/support.png`}
                  alt="support_image"
                  width={250}
                  height={150}
                />
              </div>
              <div className="text-center text-md-start">
                <h4 className="fw-bold">Risk Profile Analysis</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
            {/* ----- */}
            <div className="col-md-3">
              <div className="text-center bg-secondary-subtle rounded-3 my-3 px-3 py-5">
                <img
                  src={`${APP_URL}/assets/img/support.png`}
                  alt="support_image"
                  width={250}
                  height={150}
                />
              </div>
              <div className="text-center text-md-start">
                <h4 className="fw-bold">Entry & exit guidance</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ----------Support Section----------- */}

      {/* ----------App Section----------- */}
      <section className="py-5">
        <div className="container my-5 bg-green rounded-3">
          <div className="py-5 p-md-5 my-5">
            <div className="row g-3 align-items-center">
              <div className="col-md-6 text-center text-md-start">
                {/* <h1 className="fw-bold">Download The App</h1> */}
                <h1 className="mb-1 fw-bold">
                  Get Real-Time Recommendations at Your Finger Tips
                </h1>
                <p>
                  Our Subscribers gets all recommendations in real-time. Join
                  India's Most Popular Investment Advisory Now!
                </p>
                <div className="d-flex">
                  <button className="btn">
                    <img
                      className="rounded-3 img-fluid"
                      src={`${APP_URL}/assets/img/android-app.png`}
                      alt="support_image"
                    />
                  </button>
                  <button className="btn mt-2">
                    <img
                      className="rounded-3 img-fluid"
                      src={`${APP_URL}/assets/img/ios-app.png`}
                      alt="support_image"
                    />
                  </button>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="d-flex">
                  <div className="">
                    <img
                      src={`${APP_URL}/assets/img/mobile-app4.png`}
                      className="img-fluid"
                      alt="phone_img"
                    />
                  </div>
                  <div className="">
                    <img
                      src={`${APP_URL}/assets/img/mobile-app5.png`}
                      className="img-fluid"
                      alt="phone_img"
                    />
                  </div>
                  <div className="">
                    <img
                      src={`${APP_URL}/assets/img/mobile-app6.png`}
                      className="img-fluid"
                      alt="phone_img"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 text-center d-block d-lg-none d-md-none mt-3">
                <div className="d-flex">
                  <div className="">
                    <img

                      src={`${APP_URL}/assets/img/mobile-app4.png`}
                      className="img-fluid"
                      alt="phone_img"
                    />
                  </div>
                  <div className="">
                    <img

                      src={`${APP_URL}/assets/img/mobile-app5.png`}
                      className="img-fluid"
                      alt="phone_img"
                    />
                  </div>
                  <div className="">
                    <img

                      src={`${APP_URL}/assets/img/mobile-app6.png`}
                      className="img-fluid"
                      alt="phone_img"
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* ----------App Section----------- */}

      {/* ----------Award Section----------- */}
      <section className="">
        <div className="container my-5">
          <div className="row justify-content-center align-items-center py-5">
            <div className="col-md-12">
              <h1 className="fw-bold text-center mb-5">
                National & International Awards
              </h1>
            </div>
            <div className="col-md-12 text-center">
              <div className="row g-4">
                <div className="col-6 col-md-3">
                  <img
                    style={{ width: "100%" }}
                    src={`${APP_URL}/assets/img/award1.png`}
                    alt="support_image"
                  />
                </div>
                <div className="col-6 col-md-3">
                  <img
                    style={{ width: "100%" }}
                    src={`${APP_URL}/assets/img/award2.png`}
                    alt="support_image"
                  />
                </div>
                <div className="col-6 col-md-3">
                  <img
                    style={{ width: "100%" }}
                    src={`${APP_URL}/assets/img/award3.png`}
                    alt="support_image"
                  />
                </div>
                <div className="col-6 col-md-3 d-none">
                  <img
                    style={{ width: "100%" }}
                    src={`${APP_URL}/assets/img/award4.png`}
                    alt="support_image"
                  />
                </div>
                <div className="col-6 col-md-3">
                  <img
                    style={{ width: "100%" }}
                    src={`${APP_URL}/assets/img/award5.png`}
                    alt="support_image"
                  />
                </div>
                <div className="col-6 col-md-3">
                  <img
                    style={{ width: "100%" }}
                    src={`${APP_URL}/assets/img/award6.png`}
                    alt="support_image"
                  />
                </div>
                <div className="col-6 col-md-3">
                  <img
                    style={{ width: "100%" }}
                    src={`${APP_URL}/assets/img/award7.png`}
                    alt="support_image"
                  />
                </div>
                <div className="col-6 col-md-3">
                  <img
                    style={{ width: "100%" }}
                    src={`${APP_URL}/assets/img/award8.png`}
                    alt="support_image"
                  />
                </div>
                <div className="col-6 col-md-3">
                  <img
                    style={{ width: "100%" }}
                    src={`${APP_URL}/assets/img/award9.png`}
                    alt="support_image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ----------Award Section----------- */}

      {/* ----------Contact Us Section----------- */}
      <section className="bg-green bg-opacity-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center py-5">
                <h1 className="fw-bold">
                  Need any help? Contact To Support Team!
                </h1>
                <p>
                  We are always there for you every time and will guide you at
                  every step. <br />
                  The team of certified advisors will assist you in achieving
                  the wealth creation goal.
                </p>
                <div className="d-flex text-success justify-content-center fw-bold">
                  <p>+91 8000 816 688</p>
                  <p className="mx-2"> | </p>
                  <p>support@equitypandit.com</p>
                </div>
                <button
                  className="btn btn-dark"
                  onClick={() => handleRedirect("/contact-us")}
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-success bg-gradient d-none">
        <div className="container my-5">
          <div className="row py-5">
            <div className="col-md-12 text-center">
              <div className="bg-light rounded-3 px-3 py-5 m-2">
                <h1 className="fw-bold">
                  Take a step ahead & Invest Now with EquityPandit
                </h1>
                <h4 className="my-3" style={{ lineHeight: "1.5" }}>
                  For assistance, please call us at <b>+91 8000 816688</b> or
                  email us at <b>support@equitypandit.com</b>
                </h4>
                <button
                  className="btn btn-outline-success"
                  onClick={() => handleRedirect("/get-started")}
                >
                  Get Started <MoveRight size={16} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ----------Contact Us Section----------- */}
    </Layout>
  );
};

export default Welcome;
