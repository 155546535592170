import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {Provider} from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.min.js';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./css/font-awesome/css/all.min.css";
import "./css/font-awesome/js/all.min.js";
import "./scss/custom.scss";
import "./scss/uni.scss";
// import {library} from '@fortawesome/fontawesome-svg-core';
// import {fas} from '@fortawesome/free-solid-svg-icons';
// import {faTwitter, faFontAwesome} from '@fortawesome/free-brands-svg-icons';
import {GoogleOAuthProvider} from "@react-oauth/google";
import {persistor, store} from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";
import {GOOGLE_AUTH_TOKEN} from "./config/constants.config";

// library.add(fas);

const root = ReactDOM.createRoot(document.getElementById("root"));
const PageLoader = () => {
  return <div>Home Page</div>;
};

root.render(
  <GoogleOAuthProvider clientId={GOOGLE_AUTH_TOKEN}>
    {/*<React.StrictMode>*/}
    <Provider store={store}>
      <Suspense fallback={<PageLoader/>}></Suspense>
      <App/>
    </Provider>
    {/*</React.StrictMode>*/}
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
