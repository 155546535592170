import {useSelector} from "react-redux";
import Layout from "../../layout/interface/Layout";

export default function Profile() {
  const userDetail = useSelector((state) => state.auth.userDetail);
  return (
    <Layout>
      <section className="bg-success bg-opacity-25">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-12">
              <div className="bg-white rounded-3 p-3">
                <h3 className="fw-bold mb-4">My Profile</h3>
                <p className="fw-bold">Personal Information</p>
                <hr/>
                <div className="row">
                  <div className="col-md-3">
                    <div className="p-2">
                      <small className="text-secondary">Name</small>
                      <div>{userDetail.firstName}</div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="p-2">
                      <small className="text-secondary">Mobile</small>
                      <div>{userDetail.mobile}</div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="p-2">
                      <small className="text-secondary">Email</small>
                      <div>{userDetail.email}</div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="p-2">
                      <small className="text-secondary">Date of birth</small>
                      <div>{userDetail.dob}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
