import GuestRoute from "./GuestRoutes";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import { BrowserRouter, Routes } from "react-router-dom";
import {createContext, useEffect} from "react";
import AxiosInterceptor from "../src/axios-interceptor";
import smoothScroll from "smoothscroll-polyfill";
import "./style.css";
import {reduxFetchUserDetail} from "./redux/auth/auth";
import ProtectedRoute from "./ProtectedRoute";
import OpenRoutes from "./OpenRoutes";
import {DIGIO_ENV} from "./config/constants.config";

smoothScroll.polyfill();

function App() {
  const dispatch = useDispatch();
  const uid = useSelector((state) => state.auth.uid);
  const userDetail = useSelector((state) => state.auth.userDetail);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = DIGIO_ENV === 'sandbox' ? '/assets/digio.js' : ''
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const suat = sessionStorage.getItem("suat");
    if (suat) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${suat}`;

      if (!!uid && !userDetail.id) {
        dispatch(reduxFetchUserDetail(uid));
      }
    }
  }, []);

  const suat = sessionStorage.getItem('suat');
  if (suat)
    axios.defaults.headers.common["Authorization"] = `Bearer ${suat}`;

  return (
    <>
      {process.env.REACT_APP_REMOTE_ORIGIN !== "https://uapi.equitypandit.com/serve" &&
        <div className="sticky-top" style={{"zIndex": "2050"}}>
          <div
            className="bg-danger text-white stage-label">
            Staging
          </div>
        </div>
      }
        <AxiosInterceptor />
        <BrowserRouter>
          <Routes>
            {ProtectedRoute}
            {GuestRoute}
            {OpenRoutes}
          </Routes>
        </BrowserRouter>
    </>
  );
}
export default App;
