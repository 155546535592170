import Layout from "../layout/interface/Layout";
import {useRef, useState} from "react";
import {useLocation} from "react-router-dom";

export const MakePayment = () => {
  const [buttonText, setButtonText] = useState("copy");
  const textToSelect = useRef(null);

  const handleCopy = () => {
    navigator.clipboard.writeText('pay.equitypandit@okhdfcbank')
      .then(() => {
        if (textToSelect.current) {
          // Select the text in the input field
          textToSelect.current.select();
          textToSelect.current.setSelectionRange(0, textToSelect.current.value.length);
          setButtonText("copied");
        }
      })
      .catch(err => {
        console.error('Error copying text: ', err);
      });
  };

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const amount = queryParams.get('amount');

  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="bg-white rounded-3 border">
          <div className="row">
            <div className="col-md-6 p-5 text-center border-dark-subtle border-2">
              <h1>For UPI Payments</h1>
              <h5>Scan For Google Play, Paytm or PhonePay etc.</h5>
              <div className="w-75 m-auto">
                <h4 className="border border-2 border-info rounded py-1">Payable amount - ₹{amount}</h4>
              </div>

              <img
                src="/assets/img/upi_equitypandit.jpeg"
                alt="UPI equitypandit"
                style={{width: '250px'}}
              />
              <div>
                <div className="btn-group" role="group">
                  <input
                    ref={textToSelect}
                    readOnly={true}
                    value="pay.equitypandit@okhdfcbank"
                    className="text-center"
                    style={{outline: 'none', border: 'none', backgroundColor: "#ededed"}}
                  />
                  <button
                    type="button"
                    className={`btn ${buttonText === 'copy' ? 'btn-primary' : 'btn-success'}`}
                    onClick={handleCopy}
                  >
                    {buttonText}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-5">
              <h4>For NEFT / RTGS / Cheque Payments</h4>
              <div className="mt-5">
                <img src="/ep-imgs/bank-image.png" width="100"/>
                <div className="mt-3">
                  <span className="text-secondary">Account Name:</span> EquityPandit Financial Services Pvt Ltd.
                </div>
                <div className="mt-3">
                  <span className="text-secondary">Account Number:</span> 50200009691078
                </div>
                <div className="mt-3">
                  <span className="text-secondary">Bank Name:</span> HDFC Bank (L P SAVANI Branch)
                </div>
                <div className="mt-3">
                  <span className="text-secondary">IFSC Code:</span> HDFC0001701
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
