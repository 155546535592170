import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useDocumentTitle } from "../../components/Common";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { submitContact } from "./ContactUs.service";
import Layout from "../layout/interface/Layout";

export default function ContactUs() {
  const [successMessage, setSuccessMessage] = useState();
  const [failedMessage, setFailedMessage] = useState();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(setAfterAuthUrl("/privacy-policy"));
  // }, [dispatch]);
  useDocumentTitle("Contact Us");

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .max(150, "Name should be 150 characters only."),
    email: Yup.string()
      .required("Mobile is required")
      .max(150, "Email should be 150 characters only."),
    mobile: Yup.string()
      .required("Mobile is required")
      .min(10, "Mobile must be 10 digits.")
      .max(10, "Mobile must be 10 digits."),
    message: Yup.string()
      .required("Mobile is required")
      .max(255, "Message should be 150 characters only."),
  });

  return (
    <Layout>
      <section className="">
        <div className="container-fluid map_overlaping">
          <div className="row">
            <div className="col-md-12 px-0">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3720.0198979020015!2d72.7857393!3d21.1913685!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04dd24d17279b%3A0x28c1287f18dfae96!2sEquitypandit!5e0!3m2!1sen!2sin!4v1613716420927!5m2!1sen!2sin"
                frameBorder="0"
                style={{ width: "100%", height: "450px", border: "0" }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="pd">
        <div className="container my-5">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-12">
              <div className="rounded-3 bg_light_green">
                <div className="row">
                  <div className="col-md-3">
                    <h4 className="ps-lg-4 pt-2 fw-bold">Gujarat</h4>
                    <p className="ps-lg-4">
                      209 - The Galleria,
                      <br />
                      Near Sanjeev kumar Auditorium,
                      <br />
                      Pal, Adajan, Surat, Gujarat - 395009
                    </p>
                  </div>
                  <div className="col-md-3">
                    <h4 className="ps-lg-4 pt-2 fw-bold">Rajasthan</h4>
                    <p className="ps-lg-4">
                      A-488, Vidhyut Nagar,
                      <br />
                      Ajmer Road,
                      <br />
                      Jaipur, Rajasthan -302019
                    </p>
                  </div>
                  <div className="col-md-3">
                    <h4 className="ps-lg-4 pt-2 fw-bold">Call Us:</h4>
                    <p className="ps-lg-4">+91 800-081-6688</p>
                  </div>
                  <div className="col-md-3">
                    <h4 className="ps-lg-4 pt-2 fw-bold">Email Us:</h4>
                    <p className="ps-lg-4">support@equitypandit.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-secondary bg-opacity-10 py-5">
        <div className="container my-5">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-8 col-12">
              <div className="rounded-3 box_shadow p-3 bg-white shadow">
                <h1 className="text-center text-black fw-bold">
                  We Always Here{" "}
                  <span className="fw-bold text-success">
                    <br className="d-lg-none" />
                    To Help You
                  </span>
                </h1>
                <Formik
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={{
                    name: "",
                    email: "",
                    mobile: "",
                    page: "contact-us",
                    source: "website",
                    message: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    resetForm();
                    setSubmitting(true);
                    submitContact(values)
                      .then((res) => {
                        setSuccessMessage(res.data.message);
                      })
                      .catch((e) => {
                        setFailedMessage(e.response.data.message);
                      });
                  }}
                  enableReinitialize
                >
                  {({ isSubmitting, touched, errors, values }) => (
                    <Form className="mx-md-4 my-md-3">
                      <div className="row">
                        <div className="col-12 mt-3">
                          <div className="my-3 text-center fs-18">
                            CONTACT US
                          </div>
                        </div>

                        {successMessage && (
                          <div className="text-center mb-2 text-success">
                            <small>
                              <b>{successMessage}</b>
                            </small>
                          </div>
                        )}

                        {failedMessage && (
                          <div className="text-center mb-2 text-danger">
                            <small>
                              <b>{failedMessage}</b>
                            </small>
                          </div>
                        )}

                        <div className="col-md-4 col-12 mt-3">
                          <Field
                            name="name"
                            placeholder="Name*"
                            className={`form-control rounded-3 py-2 px-3 show-tick ${
                              touched.name && errors.name
                                ? "is-invalid"
                                : "border-dark-subtle"
                            }`}
                            value={values.name}
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="col-md-4 col-12 mt-3">
                          <Field
                            name="email"
                            placeholder="Email*"
                            className={`form-control rounded-3 py-2 px-3 show-tick ${
                              touched.email && errors.email
                                ? "is-invalid"
                                : "border-dark-subtle"
                            }`}
                            value={values.email}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="col-md-4 col-12 mt-3">
                          <Field
                            name="mobile"
                            placeholder="Mobile*"
                            className={`form-control rounded-3 py-2 px-3 show-tick ${
                              touched.mobile && errors.mobile
                                ? "is-invalid"
                                : "border-dark-subtle"
                            }`}
                            value={values.mobile}
                          />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="col-12 mt-3">
                          <Field
                            as="textarea"
                            name="message"
                            placeholder="Your Messages*"
                            rows="6"
                            cols="50"
                            className={`form-control rounded-3 py-2 px-3 show-tick ${
                              touched.message && errors.message
                                ? "is-invalid"
                                : "border-dark-subtle"
                            }`}
                            value={values.message}
                          />
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="col-12">
                          <div className="text-center my-lg-0 my-2">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-default rounded-pill text-white py-2 px-4 mt-2 bg-success"
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
