"use client";
import React, { useEffect, useState } from "react";
// import Detail from "@/guest/detail";
// import { setAfterAuthUrl } from "@/redux/module/routing";
import riskProfileTxt from "../../config/riskProfileTxt.json";
// import Service from "@/app/(public)/services/page";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import RpfPDF from "../../components/rpfPdf";
import axios from "axios";
import moment from "moment";
import {RAZORPAY_KEY, V1_API_ORIGIN} from "../../config/constants.config";
import {useDocumentTitle} from "../../components/Common";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {paymentRequest, updateRazorpaySuccess} from "../service/trading-investing/TradingInvesting.service";
import Layout from "../layout/interface/Layout";
import Service from "../service/Services";
import Detail from "../layout/interface/guest/detail";
import TradingInvesting from "../service/trading-investing/TradingInvesting";
import TradingInvestingPlans from "../../components/TradingInvestingPlans";

Chart.register(ArcElement);

const DashboardStrip = ({ paymentData }) => {
  window.paymentID = paymentData[0].id;
  const userDetail = useSelector((state) => state.auth.userDetail);
  const [timeRemaining, setTimeRemaining] = useState({});
  const router = useNavigate();

  const makePayment = () => {
    paymentRequest(userDetail).then((response) => {
      const options = {
        key: RAZORPAY_KEY,
        amount: response.data.data.amount.toString(),
        currency: "INR",
        name: "Equitypandit Financial Services Private Limited",
        description: "Packages",
        image: `/ep-imgs/EPfavicon.png`,
        order_id: response.data.data.id,
        handler: async function (response) {
          await updateRazorpaySuccess({
            payStatus: "success",
            razorpayOrderId: response.razorpay_order_id,
            razorpayPaymentId: response.razorpay_payment_id,
          }).then(() => {
            localStorage.setItem("pid", window.paymentID);
            router("/my-services");
          });
        },
        prefill: {
          name: userDetail.firstName,
          email: userDetail.email,
          contact: userDetail.mobile,
        },
        theme: {
          color: "#008254",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    });
  };

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = moment();
      const expiry = moment(paymentData[0]?.expiryDate);

      if (expiry.isAfter(now)) {
        const duration = moment.duration(expiry.diff(now));
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        setTimeRemaining({
          days,
          hours,
          minutes,
          seconds,
        });
      } else {
        setTimeRemaining("Expired");
      }
    };

    calculateTimeRemaining();

    // Set the interval only if the payment is not expired
    if (
      paymentData[0]?.expiryDate &&
      moment(paymentData[0]?.expiryDate).isAfter(moment())
    ) {
      const intervalId = setInterval(calculateTimeRemaining, 1000);
      return () => clearInterval(intervalId);
    }
  }, [paymentData[0]?.expiryDate]);

  if (
    paymentData &&
    paymentData[0]?.payStatus === "processing" &&
    paymentData[0]?.digioDocSignStatus === "success"
  ) {
    return (
      <div className="col-12 mb-3  custom_offer_html">
        <div
          className="rounded-top"
          style={{
            background:
              "linear-gradient(90.15deg, #3A44B0 0%, #725AD0 51.09%, #3A44B0 100%)",
          }}
        >
          <div className="row align-items-center">
            <div className="col-lg-6 col-6">
              <span className="text-start p-2 ms-2 fs-18 text-white">
                Complete your payment for following packages
              </span>
            </div>
            <div className="col-lg-6 col-6">
              <div className="d-flex p-3 align-items-center">
                <span className="text-white fs-14 ms-auto d-none">
                  <strike>₹50,0000</strike>
                </span>
                <span className="text-white ms-auto fs-20 ms-2">
                  <strong>₹{paymentData && paymentData[0]?.paidAmount}</strong>
                  <span className="text-white" style={{ fontSize: "xx-small" }}>
                    {" "}
                    GST(18%) INCLUDED
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="rounded-bottom"
          style={{
            backgroundColor: "rgba(242, 238, 255, 1)",
            border: "1px solid rgba(211, 195, 255, 1)",
          }}
        >
          <div className="row align-items-center mt-2">
            {Object.values(paymentData?.[0]?.cartItems)?.map((item, index) => (
              <div className="col-lg-6 px-3 pt-2" key={index}>
                <span className="text-start p-2 ms-2 rounded-pill bg-info bg-opacity-10 fw-semibold">
                  {item.name}
                </span>
                <span className="text-start p-2 ms-2 fs-14 fw-semibold d-none">
                  Stock Option
                </span>
                <div className="ms-2 py-1 px-2"></div>
                <span
                  className="text-start p-2 ms-2 fs-12"
                  style={{ color: "rgba(65, 65, 65, 1)" }}
                ></span>
              </div>
            ))}
            <div className="col-lg-6 px-3 pt-2 mb-2 ms-2">
              <button
                className="btn btn-success btn-sm"
                onClick={() => makePayment()}
              >
                Make Payment
              </button>
            </div>
            <div className="col-lg-6 px-3 py-2 d-none">
              <div className="align-content-end px-2 d-flex align-items-center">
                <span className="fw-bold fs-10 me-2 ms-auto">Expiring In</span>
                <div className="d-flex flex-column mt-3 text-center">
                  <span
                    className="fw-bold px-2 py-1 timer-gradient-border"
                    id="days_count"
                  >
                    2
                  </span>
                  <span className="fs-12">Days</span>
                </div>
                <span className="fw-bold fs-10 mx-2">:</span>
                <div className="d-flex flex-column mt-3 text-center">
                  <span
                    className="fw-bold px-2 py-1 timer-gradient-border"
                    id="hours_count"
                  >
                    03
                  </span>
                  <span className="fs-12">Hours</span>
                </div>
                <span className="fw-bold fs-10 mx-2">:</span>
                <div className="d-flex flex-column mt-3 text-center">
                  <span
                    className="fw-bold px-2 py-1 timer-gradient-border"
                    id="minute_count"
                  >
                    55
                  </span>
                  <span className="fs-12">Minutes</span>
                </div>
                <span className="fw-bold fs-10 mx-2">:</span>
                <div className="d-flex flex-column mt-3 text-center">
                  <span
                    className="fw-bold px-2 py-1 timer-gradient-border"
                    id="second_count"
                  >
                    40
                  </span>
                  <span className="fs-12">Seconds</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (
    paymentData[0]?.expiryDate &&
    moment(paymentData[0]?.expiryDate).isAfter(moment())
  ) {
    return (
      <div className="col-12 mb-3  custom_offer_html">
        <div
          className="rounded-top"
          style={{
            background:
              "linear-gradient(90.15deg, #3A44B0 0%, #725AD0 51.09%, #3A44B0 100%)",
          }}
        >
          <div className="row align-items-center">
            <div className="col-lg-6 col-6">
              <span className="text-start p-2 ms-2 fs-18 text-white">
                Complete your payment
              </span>
            </div>
            <div className="col-lg-6 col-6">
              <div className="d-flex p-3 align-items-center">
                <span className="text-white fs-14 ms-auto d-none">
                  <strike>₹50,0000</strike>
                </span>
                <span className="text-white ms-auto fs-20 ms-2">
                  <strong>₹{paymentData && paymentData[0]?.paidAmount}</strong>
                  <span className="text-white" style={{ fontSize: "xx-small" }}>
                    {" "}
                    GST(18%) INCLUDED
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="rounded-bottom"
          style={{
            backgroundColor: "rgba(242, 238, 255, 1)",
            border: "1px solid rgba(211, 195, 255, 1)",
          }}
        >
          <div className="row align-items-center">
            {Object.values(paymentData?.[0]?.cartItems)?.map((item, index) => (
              <div className="col-lg-6 px-3 pt-2" key={index}>
                <span className="text-start p-2 ms-2 rounded-pill bg-info bg-opacity-10 fw-semibold">
                  {item.name}
                </span>
                <span className="text-start p-2 ms-2 fs-14 fw-semibold d-none">
                  Stock Option
                </span>
                <div className="ms-2 py-1 px-2"></div>
                <span
                  className="text-start p-2 ms-2 fs-12"
                  style={{ color: "rgba(65, 65, 65, 1)" }}
                ></span>
              </div>
            ))}
            <div className="col-lg-6 px-3 py-2 d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <button className="btn btn-success btn-sm">Make Payment</button>
              </div>
              <div className="align-content-end px-2 d-flex align-items-center">
                <span className="fw-bold fs-10 me-2 ms-auto">Expiring In</span>
                <div className="d-flex flex-column mt-3 text-center">
                  <span
                    className="fw-bold px-2 py-1 timer-gradient-border"
                    id="days_count"
                  >
                    {timeRemaining.days}
                  </span>
                  <span className="fs-12">Days</span>
                </div>
                <span className="fw-bold fs-10 mx-2">:</span>
                <div className="d-flex flex-column mt-3 text-center">
                  <span
                    className="fw-bold px-2 py-1 timer-gradient-border"
                    id="hours_count"
                  >
                    {timeRemaining.hours}
                  </span>
                  <span className="fs-12">Hours</span>
                </div>
                <span className="fw-bold fs-10 mx-2">:</span>
                <div className="d-flex flex-column mt-3 text-center">
                  <span
                    className="fw-bold px-2 py-1 timer-gradient-border"
                    id="minute_count"
                  >
                    {timeRemaining.minutes}
                  </span>
                  <span className="fs-12">Minutes</span>
                </div>
                <span className="fw-bold fs-10 mx-2">:</span>
                <div className="d-flex flex-column mt-3 text-center">
                  <span
                    className="fw-bold px-2 py-1 timer-gradient-border"
                    id="second_count"
                  >
                    {timeRemaining.seconds}
                  </span>
                  <span className="fs-12">Seconds</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default function Dashboard() {
  const [riskProfileText, setRiskProfileText] = useState("");
  const [hasPayment, setHasPayment] = useState([{ cartItems: [] }]);
  const userDetail = useSelector((state) => state.auth.userDetail);
  const router = useNavigate();
  const dispatch = useDispatch();

  const fetchPayment = (uid) => {
    axios
      .get(`${V1_API_ORIGIN}/payments?userId=${uid}&perPage=1&currentPage=1`)
      .then((response) => {
        setHasPayment(response.data.data.dataList);
      });
  };

  useEffect(() => {
      const uid = sessionStorage.getItem("uid");
      fetchPayment(uid);
  }, []);
  // useEffect(() => {
  //   dispatch(setAfterAuthUrl("/risk-profile"));
  // }, [dispatch]);
  useDocumentTitle("Dashboard");

  useEffect(() => {
    if (userDetail.email && !userDetail.rpfProfileType) {
      router("/risk-profile");
    }

    if (userDetail.rpfProfileType === "Aggressive") {
      setRiskProfileText(riskProfileTxt[userDetail.rpfProfileType]);
    } else if (userDetail.rpfProfileType === "Moderate") {
      setRiskProfileText(riskProfileTxt[userDetail.rpfProfileType]);
    } else if (userDetail.rpfProfileType === "Conservative") {
      setRiskProfileText(riskProfileTxt[userDetail.rpfProfileType]);
    }
  }, [userDetail.email]);

  return (
    <Layout>
      {userDetail.id && !userDetail.email ? (
        <>
          <div>
            <section
              id="hero"
              className="d-flex align-items-center justify-content-center flex-column pt-lg-0 pt-3"
            >
              <div className="container">
                <div className="row gy-lg-4 justify-content-center">
                  <div className="col-lg-6 d-flex flex-column text-center text-md-start">
                    <h1
                      style={{ letterSpacing: "2px" }}
                      className="pb-lg-3 pb-4 pt-lg-5 pt-md-0 lh-base fw-bold text-black fs_res_32"
                    >
                      Get your financial plan for FREE!
                    </h1>
                    <p
                      className="d-lg-block d-none"
                      style={{ letterSpacing: "1px" }}
                    >
                      Our Methodical Risk Profiling Process Is Tailored to
                      Determine the Ideal Investment Risk Level That Aligns
                      Perfectly with Your Financial Ambitions. Trust Us to
                      Balance Your Risk Needs, Capacity, and Personal Tolerance,
                      Crafting a Customized Strategy for Your Long-Term Success.
                    </p>
                  </div>
                  <div className="col-lg-4 offset-lg-1">
                    <div className="card text-center p-3 mt-lg-5">
                      <small
                        className="text-center"
                        style={{ letterSpacing: "1px" }}
                      >
                        Get Your Risk Profile
                      </small>
                      <h4
                        style={{ letterSpacing: "1px" }}
                        className="fw-bold text-center"
                      >
                        Just in a Few Minutes!
                      </h4>
                      <Detail />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="mt-3 p-0 bg_light_green w-100">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="text-black text-center py-3 fs-18">
                      <span className="mx-lg-0 mx-5">
                        We Are Happy To Help You!
                      </span>
                      <br className="d-lg-none" />
                      <a href="tel:+918000816688" className="mx-lg-1 mx-1">
                        <i className="bi bi-telephone-fill text-black text-md-center me-2"></i>
                        <span className="text-black">+918000816688</span>
                      </a>
                      <br className="d-lg-none" />
                      <a
                        href="mailto:support@equitypandit.com"
                        className="mx-lg-1 mx-1"
                      >
                        <i
                          className="bi bi-envelope-fill text-black me-2"
                          aria-hidden="true"
                        ></i>
                        <span className="text-black">
                          support@equitypandit.com
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      ) : (
        ![null, "filled"].includes(userDetail.rpfProfileType) && (
          <>
            <section className="py-5">
              <div className="container">
                <div className="row g-3 align-items-center">
                  <div className="col-md-8 order-2 order-md-1">
                    <div className="text-center text-md-start">
                      <p className="mb-1">Risk Profile Type</p>
                      <h3 className="fw-bold text-success mb-3">
                        {userDetail.rpfProfileType}
                      </h3>
                      <div className="d-flex justify-content-md-start justify-content-center align-items-center mb-3">
                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target="#rpf_details"
                        >
                          View RPF
                        </button>
                        {userDetail.rpfProfileType !== "Aggressive" && (
                          <>
                            <button
                              className="btn btn-sm btn-outline-dark ms-2"
                              onClick={() => {
                                router("/risk-profile");
                              }}
                            >
                              Update Profile
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: riskProfileText }}
                    ></div>
                  </div>
                  <div className="col-md-4 px-5 order-1 order-md-2">
                    <p className="text-black fw-bold text-center">
                      Your risk profile score is {userDetail.rpfScoreInPer || 0}
                      %
                    </p>
                    <Doughnut
                      data={{
                        labels: ["", ""],
                        datasets: [
                          {
                            label: "Risk Profile",
                            data: [
                              userDetail.rpfScoreInPer,
                              100 - userDetail.rpfScoreInPer,
                            ],
                            backgroundColor: ["#347521", "#a4c695"],
                            borderWidth: 0,
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          title: {
                            display: true,
                            text: "Risk Profile",
                          },
                        },
                        rotation: 0,
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="container">
                <div className="row">
                  {hasPayment?.[0] && (
                    <DashboardStrip paymentData={hasPayment} />
                  )}
                </div>
              </div>
            </section>
            <TradingInvestingPlans />
          </>
        )
      )}

      <div
        className="modal fade"
        id="rpf_details"
        tabIndex="-1"
        aria-labelledby="service_processLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-confirm modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content table">
            <div className="d-flex justify-content-end me-2 mt-2">
              <span
                className="btn-close btn-close-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></span>
            </div>
            <div className="modal-body pt-1 mb-2">
              {userDetail?.rpfId && <RpfPDF rpfId={userDetail.rpfId} />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
