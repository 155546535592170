import axios from "axios";
import {V1_API_ORIGIN} from "../../../config/constants.config";

export const loginService = (params) => {
  let payload = {...params};
  return axios.post(`${V1_API_ORIGIN}/users/login`, payload);
}

export const otpVerifyService = (params) => {
  let payload = {...params};
  return axios.post(`${V1_API_ORIGIN}/users/otp-verify`, payload);
}

export const logoutService = () => {
  return axios.get(`${V1_API_ORIGIN}/users/logout`);
}

export const updateUserDetailService = (params) => {
  let payload = {...params};
  delete payload.tNC;
  const uid = payload.uid;
  delete payload.uid;
  return axios.post(`${V1_API_ORIGIN}/users/${uid}`, payload);
}

export const fetchUserDetailService = (uid) => {
  return axios.get(`${V1_API_ORIGIN}/users/${uid}`);
}
