import axios from "axios";
import {V1_API_ORIGIN} from "../../../config/constants.config";

export const submitSupport = (payload) => {
  return axios.post(`${V1_API_ORIGIN}/support`, payload);
}

export const getTickets = () => {
  if (typeof window !== 'undefined') {
    const uid = sessionStorage.getItem('uid');
    return axios.get(`${V1_API_ORIGIN}/support?userId=${uid}&perPage=10&currentPage=1&ticketStatus=close&sortByKey=createdAt&sortByValue=ASC`);
  }
}

export const getOpenTicket = () => {
  const uid = sessionStorage.getItem('uid');
  return axios.get(`${V1_API_ORIGIN}/support?userId=${uid}&perPage=10&currentPage=1&ticketStatus=open&sortByKey=createdAt&sortByValue=ASC`);
}
