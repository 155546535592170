"use client";
import React from "react";
import "./service.css";
import {useNavigate} from "react-router-dom";
import {handleScrollToTop, useDocumentTitle} from "../../components/Common";

export default function Service() {
  const router = useNavigate();
  useDocumentTitle("Service");

  function handleRedirect(link) {
    router(link);
    handleScrollToTop();
  }

  return (
    <>
      {/* ----------Service Section----------- */}
      <section className="bg-secondary bg-opacity-25">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-12 text-center">
              {/*<h4 className="mb-1">What we do</h4>*/}
              <h1 className="fw-bold mb-5">Our Services</h1>

              <div className="row align-items-center bg-light rounded-3 px-3 py-5 mx-2 mx-md-0">
                <div className="col-md-7 text-center text-md-start">
                  <h1 className="fw-bold">Investing & Trading</h1>
                  <p>
                    {`Lorem Ipsum is simply dummy text of the printing and
                                          typesetting industry. Lorem Ipsum has been the industry's
                                          standard dummy text ever since the 1500s, when an unknown
                                          printer took a galley of type and scrambled it to make a
                                          type specimen book.`}
                  </p>
                  <button
                    className="btn btn-md btn-success mb-3"
                    onClick={() => handleRedirect("trading-investing")}
                  >
                    Know More
                  </button>
                </div>
                <div className="col-md-5">
                  <img
                    className="rounded-3"
                    style={{ width: "100%" }}
                    src="/assets/img/service.png"
                    alt="support_image"
                  />
                </div>
              </div>

              <div className="row align-items-center bg-light rounded-3 my-3 px-3 py-5 mx-2 mx-md-0">
                <div className="col-md-5 order-2 order-md-1">
                  <img
                    className="rounded-3"
                    style={{ width: "100%" }}
                    src="/assets/img/service.png"
                    alt="support_image"
                  />
                </div>
                <div className="col-md-7 text-center text-md-start order-1 order-md-2">
                  <h1 className="fw-bold">Institutional Investors</h1>
                  <p>
                    {`Lorem Ipsum is simply dummy text of the printing and
                                          typesetting industry. Lorem Ipsum has been the industry's
                                          standard dummy text ever since the 1500s, when an unknown
                                          printer took a galley of type and scrambled it to make a
                                          type specimen book.`}
                  </p>
                  <button
                    className="btn btn-md btn-success mb-3"
                    onClick={() =>
                      handleRedirect("institutional-investors")
                    }
                  >
                    Know More
                  </button>
                </div>
              </div>

              <div className="row align-items-center bg-light rounded-3 px-3 py-5 mx-2 mx-md-0">
                <div className="col-md-7 text-center text-md-start">
                  <h1 className="fw-bold">HNI & UHNI Services</h1>
                  <p>
                    {`Lorem Ipsum is simply dummy text of the printing and
                                          typesetting industry. Lorem Ipsum has been the industry's
                                          standard dummy text ever since the 1500s, when an unknown
                                          printer took a galley of type and scrambled it to make a
                                          type specimen book.`}
                  </p>
                  <button
                    className="btn btn-md btn-success mb-3"
                    onClick={() => handleRedirect("hni-uhni")}
                  >
                    Know More
                  </button>
                </div>
                <div className="col-md-5">
                  <img
                    className="rounded-3"
                    style={{ width: "100%" }}
                    src="/assets/img/service.png"
                    alt="support_image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ----------Service Section----------- */}
    </>
  );
}
