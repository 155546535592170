"use client";
import React from "react";
import { useDocumentTitle } from "../../components/Common";
import Layout from "../layout/interface/Layout";
import { APP_URL } from "../../config/constants.config";
import { CheckCheckIcon, CheckIcon, MoveRight } from "lucide-react";

const TheCompany = () => {
  useDocumentTitle("The Company");
  return (
    <Layout>
      <section className="">
        <div className="container my-5">
          <div className="row g-3 align-items-center justify-content-center">
            <div className="col-md-7 col-12">
              <h1 className="fw-bold mb-4">We are...</h1>
              <h5 className="mb-3">
                <CheckIcon /> Pioneer of the equity research industry.
              </h5>
              <h5 className="mb-3">
                <CheckIcon /> We Known for the best in stock research.
              </h5>
              <h5 className="mb-3">
                <CheckIcon /> We empower subscribers with training & workshops.
              </h5>
              <h5 className="mb-3">
                <CheckIcon /> A contributor to the world’s biggest financial
                prtals.
              </h5>
            </div>
            <div className="col-md-5 col-12 text-center">
              <img
                className="img-fluid w-100"
                src={`${APP_URL}/assets/img/about-us.png`}
                alt="support_image"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-green">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-12">
              <h1 className="fw-bold">Awards & Recognitions</h1>
              <p className="mb-4">
                National & International Awards{" "}
                <small className="fw-bold">(Since 2006)</small>
              </p>
              <div className="row g-3 d-flex flex-nowrap overflow-auto ">
                <div className="col-md-3 col-9">
                  <div className="text-center h-100 bg-white p-3 rounded-3">
                    <img
                      className=""
                      style={{ width: "35px" }}
                      src={`${APP_URL}/assets/img/awarded-as.png`}
                      alt="support_image"
                    />
                    <p style={{ letterSpacing: 1.5 }}>
                      <small>AWARDED AS</small>
                    </p>
                    <p className="fw-bold">
                      {`India’s Leading Equity Research Company`}
                    </p>

                    <small>- By World Signature Awards</small>
                  </div>
                </div>
                {/* ---------- */}
                <div className="col-md-3 col-9">
                  <div className="text-center h-100 bg-white p-3 rounded-3">
                    <img
                      className=""
                      style={{ width: "35px" }}
                      src={`${APP_URL}/assets/img/awarded-as.png`}
                      alt="support_image"
                    />
                    <p style={{ letterSpacing: 1.5 }}>
                      <small>AWARDED AS</small>
                    </p>
                    <p className="fw-bold">
                      {`Most Innovative Company in BFSI`}
                    </p>

                    <small>- By The Economic Times</small>
                  </div>
                </div>
                {/* ---------- */}
                <div className="col-md-3 col-9">
                  <div className="text-center h-100 bg-white p-3 rounded-3">
                    <img
                      className=""
                      style={{ width: "35px" }}
                      src={`${APP_URL}/assets/img/awarded-as.png`}
                      alt="support_image"
                    />
                    <p style={{ letterSpacing: 1.5 }}>
                      <small>AWARDED AS</small>
                    </p>
                    <p className="fw-bold">
                      {`Fastest Growing Market  Research Company in India`}
                    </p>

                    <small>- By CEO Magazine</small>
                  </div>
                </div>
                {/* ---------- */}
                <div className="col-md-3 col-9">
                  <div className="text-center h-100 bg-white p-3 rounded-3">
                    <img
                      className=""
                      style={{ width: "35px" }}
                      src={`${APP_URL}/assets/img/awarded-as.png`}
                      alt="support_image"
                    />
                    <p style={{ letterSpacing: 1.5 }}>
                      <small>AWARDED AS</small>
                    </p>
                    <p className="fw-bold">
                      {`Highly Recommended Financial  Advisor For The Year`}
                    </p>

                    <small>- By Enterprise World</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container py-5">
          <div className="row g-3">
            <div className="col-md-8">
              <h1 className="fw-bold mb-3">About Us</h1>
              <p>
                EquityPandit is been promoted by a group of Stock Market
                analysts who are certified by National Stock exchange and other
                International certifications and have experience of more than
                5-10 years of Technical and fundamental analysis.
              </p>
              <p>
                EquityPandit is a leading research and advisory firm in India,
                The firm is one of the biggest player with a dominant position
                in both Institutional and Retail. Company specializes in the
                business of Analysis, Information and Intelligence. We have made
                an effort at to bring you the top technical, fundamental basics
                of stock.
              </p>
              <p>
                Each and every space of this site is resourceful and found to be
                useful and interesting for making money. We offers a wide range
                of Web Portal and SMS services for the discerning equity
                investors and traders, We are sure that you may find these
                features for your enrichment as we do well for ourselves.
              </p>
              <p>
                Further we would like to clarify that at present we do not
                undertake E-trading or any transactions on behalf of our
                valuable visitors or clients but we work only as a guide to
                serve them for their betterment.
              </p>
            </div>
            <div className="col-md-4">
              <img
                className="img-fluid"
                src={`${APP_URL}/assets/img/about-ep.png`}
                alt="support_image"
              />
            </div>
            <div className="col-md-12">
              {/* <hr /> */}

              <div className="wpb_wrapper">
                <p>
                  The business is supported by an efficient powerful research
                  and back office team. EquityPandit’s set of diligent advisors
                  helps its customers plan and get more out of one’s money.
                </p>
                <p>
                  The firm’s philosophy is entirely client centric, with a clear
                  focus on providing long term value addition to clients, while
                  maintaining the highest standards of excellence, ethics and
                  professionalism. The entire firm activities are divided across
                  distinct client groups, Individuals, Private Clients,
                  Corporates and Institutions all of which are supported by
                  Powerful research teams.
                </p>
                <p>
                  We offer diversified range of products according to the
                  difference in the Needs of an investor, trader or a broker.
                  With a very experienced research team consisting of each and
                  every analyst having an experience of more than 5-10 years in
                  the field, we assure 85-90% accuracy in our recommendations
                  with personal attention given to each and every client.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TheCompany;
