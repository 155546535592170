import axios from "axios";
import moment from "moment/moment";
import {V1_API_ORIGIN} from "../../../config/constants.config";

export const getRiskProfileService = () => {
  return axios.get(`${V1_API_ORIGIN}/rpfs/questions`);
}

export const getFilledRiskProfileService = (rpfID) => {
  return axios.get(`${V1_API_ORIGIN}/rpfs/${rpfID}`)
    .then((response) => {
      window.hasAge = moment().diff(moment(response.data.data.dob, 'YYYY-MM-DD'), 'years')
      let ansData = {
        userId: response.data.data.userId,
        occupation: response.data.data.occupation,
        investmentGoal: response.data.data.investmentGoal,
        monthlyIncome: response.data.data.monthlyIncome,
        dob: response.data.data.dob,
        userAnswers: [],
      };
      for (const element of response.data.data.questions) {
        for (const elementChild of element.options) {
          if (elementChild.isSelected) {
            elementChild.queCategory = element.queCategory;
            elementChild.optId = elementChild.id;
            delete elementChild.id
            delete elementChild.optText
            delete elementChild.showHide
            delete elementChild.isSelected
            ansData.userAnswers.push(elementChild);
          }
        }
      }

      return ansData;
    });
}

export const submitRiskProfile = (params) => {
  let payload = {...params};
  payload.userId = Number.parseInt(payload.userId);
  return axios.post(`${V1_API_ORIGIN}/rpfs/answers`, params);
}
